<template>
  <div class="row p-2">

    <div class="col-12">
      <!-- <div class="d-flex">
        <label>between: </label>
        <MazPicker v-model="from" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
        <label>and</label>
        <MazPicker v-model="to" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
      </div> -->
      <date-filter v-model="filter" @input="triggerInitData" :search-date-type.sync="filter.search_date_type" />
    </div>
    <div class="col-6 mt-2">
      <table class="table table-bordered">
        <thead>
          <th></th>
          <th>Tourist</th>
          <th>Resident</th>
        </thead>
        <tr v-for="(label, index) in chartData.labels" :key="label">
          <td>{{ label }}</td>
          <td>{{ chartData.datasets[0].data[index] }}</td>
          <td>{{ chartData.datasets[1].data[index] }}</td>
        </tr>
        <tr>
          <td>SUM</td>
          <td>{{chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
          <td>{{chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
        </tr>
      </table>
    </div>
    <div class="col-6 mt-2">
      <Bar :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
        :chart-id="'patients_number_chart'" :width="400" :height="400" />
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import Chart from 'chart.js/auto';
import { db } from '../../db'
import moment from "moment"
export default {
  // props: ['patients'],
  components: {
    Bar
  },
  data() {
    return {
      selectedMonth: this.$moment().month(),
      selectedYear: this.$moment().year(),
      // from: this.$moment().add(-1,'month').format('DD/MM/YYYY'),
      // to: this.$moment().format('DD/MM/YYYY'),
      chartOptions: {
        responsive: true,
        plugins: {

          legend: {
            labels: {
              color: 'black' // legend label font color
            }
          },
          datalabels: {
            color: 'white', // font color inside bars
            font: {
              weight: 'bold',
              size: 12
            },
            formatter: (value, context) => {
              const allData = context.chart.data.datasets;
              const index = context.dataIndex;

              // Calculate total for the same index across all bars
              let total = 0;
              for (let ds of allData) {
                // Avoid "Total" dataset when summing
                if (ds.label !== 'Total') {
                  total += ds.data[index] || 0;
                }
              }

              if (total === 0) return '0%'; // prevent division by zero

              const percent = ((value / total) * 100).toFixed(1);
              return `${value} (${percent}%)`; // or `${value} (${percent}%)`
            }
          },
          tooltip: {
            bodyColor: 'white',
            titleColor: 'white'
          }
        },
        scales: {
          x: {
            ticks: {
              color: 'black'
            }
          },
          y: {
            ticks: {
              color: 'black'
            }
          }
        }
      },
      filteredPatients: [],
      filter: {
        from: this.$moment().startOf('month').format('DD/MM/YYYY'),
        to: this.$moment().format('DD/MM/YYYY'),
        search_date_type: 'by-range'
      },
    }
  },

  computed: {
    chartData() {

      let filteredPatients = this.filteredPatients

      let fromMoment = this.$moment(this.filter.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.filter.to, 'DD/MM/YYYY')

      // for (let p of this.patients) {
      //   if (p.created_at && typeof p.created_at !== 'string') {
      //     if (
      //       this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) &&
      //       this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
      //     ) filteredPatients.push(p)
      //   }
      // }

      // generate months labels


      //label
      let labels = []
      let startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')

      while (startLabelMoment.isSameOrBefore(toMoment)) {
        labels.push(startLabelMoment.format('MMM YYYY'))
        startLabelMoment.add(1, 'month')
      }

      //data
      startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')
      let touristData = []
      let residentData = []
      let totalData = []
      while (startLabelMoment.isSameOrBefore(toMoment)) {
        let tcount = 0
        let rcount = 0
        for (let p of filteredPatients) {
          if (p.created_at && typeof p.created_at !== 'string') {
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.is_tourist
            ) tcount++
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.is_resident
            ) rcount++
            
          }
        }
        touristData.push(tcount)
        residentData.push(rcount)
        totalData.push(tcount + rcount)
        startLabelMoment.add(1, 'month')
      }


      return {
        labels,
        datasets: [
          {
            label: 'Tourist',
            data: touristData,
            backgroundColor: [
              'rgb(25, 118, 210)',
            ],
            borderColor: [
              'rgb(25, 118, 210)',

            ],
            borderWidth: 1
          },
          {
            label: 'Resident',
            data: residentData,
            backgroundColor: [
              'rgb(76, 175, 80)',
            ],
            borderColor: [
              'rgb(76, 175, 80)',
            ],
            borderWidth: 1
          },
          // {
          //   label: 'Total',
          //   data: totalData,
          //   borderWidth: 1
          // },

        ]
      }
    }
  },
  methods: {
    async loadData() {
      const loader = this.$loading.show({})
      this.filteredPatients = []
      try {

        let start = moment(this.filter.from, "DD/MM/YYYY").startOf('day').toDate()
        let end = moment(this.filter.to, "DD/MM/YYYY").endOf('day').toDate()

        let patientRef = await db.collection('Patient')
          .where("created_at", ">=", start)
          .where("created_at", "<", end)
          .get()
        this.filteredPatients = patientRef.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          }
        })

      }
      catch (error) {
        console.error("Error getting documents: ", error);
      }
      finally {
        loader.hide()
      }
    },

    triggerInitData() {

      this.loadData()

    },
  }
}
</script>