<template>
    <div class="news">
        <div class="news-nav">
            <ul>
                <li v-for="(item, index) in menuItems" :key="index"
                    :class="{ active: currentComponent === item.component }">
                    <a href="#" @click.prevent="navigateTo(item.component)">
                        {{ item.text }}
                    </a>
                </li>
            </ul>
        </div>
        <div v-if="currentComponent" class="content-wrapper" :class="{
            'content-Manage': currentComponent === 'ManageIndex',
            'another-class': currentComponent === 'OtherComponent'
        }">
            <component :is="currentComponent" />
        </div>
    </div>
</template>

<script>
import AnnouncementIndex from "./AnnouncementIndex.vue";
import ManageIndex from "./ManageIndex.vue";
// import MeetingReport from "./MeetingIndex.vue";
// import Policy from "./PolicyIndex.vue";


export default {
    components: {
        AnnouncementIndex,
        ManageIndex,
        // MeetingReport,
        // Policy,
    },
    data() {
        return {
            currentComponent: "AnnouncementIndex",
            menuItems: [
                { text: "HOME", component: null },
                { text: "NEWS", component: "AnnouncementIndex" },
                // { text: "Meeting", component: "MeetingReport" },
                // { text: "Schedule", component: null },
                // { text: "Policy", component: "Policy" },
                { text: "SETTING", component: "ManageIndex" },
            ],
        };
    },
    methods: {
        navigateTo(componentName) {
            if (!componentName) {
                this.$router.push('/'); // กรณี "Home"
            } else {
                this.currentComponent = componentName; // อื่นๆ
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.news {
    font-family: 'Arial', 'Sarabun' !important;
    font-size: 18px ;
    font-weight: bold;
    // background-color: #f3f3f3;
    .news-nav {
        background-color: #0b97e5;
        width: 100%;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
    }

    .news-nav ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .news-nav li {
        margin: 0 15px;
    }

    .news-nav li.active a {
        // background-color: #d0dfe7;
        color: rgb(255, 255, 255);
    }

    .news-nav a {
        color: white;
        text-decoration: none;
        font-weight: bold;
        cursor: pointer;
    }

    .news-nav a:hover {
        text-decoration: underline;
    }

    /* จัดให้อยู่ตรงกลางและมีขนาด 80% */
    .content-wrapper {
        max-width: 100%;
        /* ปรับความกว้างไม่ให้เกิน */
        //  overflow-x: hidden; /* หลีกเลี่ยงการเลื่อนขอบเกินในแนวนอน */
        height: auto;
        /* ไม่จำกัดความสูง ให้เป็นตามเนื้อหาที่มี */
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        background: #ffffff !important;
        padding: 20px;
    }

    .content-Manage {
        width: 100%;
        margin: auto;
        // background: #d41010 !important;
    }
}
</style>