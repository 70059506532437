<template>
  <div>
    <b-card class="ManageIndex" >
      <!-- Nav Tabs -->
      <b-nav class="ml-0" tabs >
        <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
          @click="currentMenu = menu.name">
          {{ menu.name }}
        </b-nav-item>
      </b-nav>
      <!-- Show Announcement Tab -->
      <div v-if="currentMenu === 'Announcement'">
        <div style="margin-top: 5px;">
          <Announcement :staff="staff" />
        </div>
      </div>

      <!-- Show Meeting Tab -->
      <!-- <div v-if="currentMenu === 'Meeting'">
        <div style="padding: 3px;">
          <Meeting :staff="staff" />
        </div>
      </div> -->

      <!-- Show Policy Tab -->
      <!-- <div v-if="currentMenu === 'Policy'">
        <div style="padding: 3px;">
          <Policy :staff="staff" />
        </div>
      </div> -->

        <!-- Show GuidelineTrainging Tab -->
        <!-- <div v-if="currentMenu === 'Guideline & Training'">
        <div style="padding: 3px;">
          <GuidelineTrainging :staff="staff" />
        </div>
      </div> -->
      
    </b-card>
  </div>

</template>

<script>
import Announcement from './Manage/ManageNews.vue'
// import Meeting from './Manage/ManageMeeting.vue'
// import Policy from './Manage/ManagePolicy.vue'
// import GuidelineTrainging from './Manage/ManageGuidelineTrainging.vue'

export default {
  components: {
    Announcement,
    // Meeting,
    // Policy,
    // GuidelineTrainging,
  },
  data() {
    return {
      // Initialize currentMenu to the first menu item
      currentMenu: 'Announcement', // This is the default menu that will be active on load
      menus: [
        { name: 'Announcement' },
        // { name: 'Meeting' },
        // { name: 'Policy' },
        // { name: 'Guideline & Training'}
      ],
      staff: [] // You can populate staff data as needed
    }
  },
  mounted() {
    // Optionally fetch or populate staff data when the component is mounted
  }
}
</script>
<style lang="scss" scoped>
.ManageIndex {
  margin-top: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: white !important;
}
</style>