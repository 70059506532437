<template>
  <div class="ManageNewIndex">
      <div class="row">
        <div class="col-3">
          <div class="mb-1">
            <input type="text" class="form-control" v-model="searchQuery" placeholder="🔍  Search" />
          </div>
        </div>
        <div class="col-8">
          <button class="btn btn-primary" @click="openAddModal">Add News</button>
        </div>
      </div>
      <!-- Table -->
      <vue-good-table :columns="columns" 
        :rows="filteredUNews" :pagination-options="{
        enabled: true,
        perPage: itemsPerPage,
        mode: 'pages'
      }" styleClass="table table-striped" 
      @on-row-click="editNews($event.row)">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'tag'">
            <i class="fa fa-tag" aria-hidden="true" v-if="props.row.Hold === true" style="color:forestgreen;" />
          </span>

          <span v-else-if="props.column.field === 'index'">
            {{ props.index + 1 }}
          </span>

          <span v-else-if="props.column.field === 'title'">
            <span v-html="truncateHtml(props.row.Title, 40)"></span>
          </span>

          <span v-else-if="props.column.field === 'content'">
            <span v-html="truncateHtml(props.row.Content, 40)"></span>
          </span>
          <span v-else-if="props.column.field === 'department'">
            <div class="gap-2 text-center">
              <div class="Department">
              <button v-for="(dept, index) in props.row.Department" :key="index" class="btn btn-sm mx-1"
                :style="{
                  backgroundColor: getColorDepartment(dept.value),
                  color: dept.value === 'WA' || dept.value === 'MT' ? '#000000' : '#fff' + ' !important',
                  fontSize: '8px',
                  fontWeight: 'bold',
                  padding: '5px 5px',
                }">
                {{ dept.label }}
              </button>
              <button v-if="props.row.Department.length > 5" :id="'tooltip-card-' +  props.row?.id"
                class="button-tooltip">
                More
              </button>
              <b-popover v-if="props.row.Department.length > 5" :target="'tooltip-card-' + props.row?.id"
                triggers="hover" style="background-color: black !important;">
                <div class="tooltip-department">
                  <button v-for="(dept, index) in props.row.Department.slice(5)" :key="index" class="btn btn-sm mx-1"
                    :style="{
                      backgroundColor: getColorDepartment(dept.value),
                      color: dept.value === 'WA' || dept.value === 'MT' ? '#000000' : '#fff' + ' !important',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      padding: '5px 5px',
                      marginBottom: '2px'
                    }">
                    {{ dept.label }}
                  </button>
                </div>
              </b-popover>
            </div>
            </div>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    <!---------------------------------------------- Add Data Modal ---------------------------------------------------------------------------->
    <div v-if="showModal" class="modal" tabindex="-1">
      <div class="modal-Detail">
        <h4 class="modal-title modal-header" style="margin-top: -15px;">
          {{ isEditing ? 'Edit News' : 'Add News' }}
          <button v-if="isEditing && ($store.state.user.assigned_roles[0].name === 'SystemAdmin') || ($store.state.user.assigned_roles[0].name === 'HOP')" 
                  class="btn btn-danger delete-btn" @click="openConfirmDelete" :disabled="loading">
            Delete
          </button>
        </h4>
        <!-- Category -->
        <div class="form-Topic">
          <div class="row">
            <div class="col-3 d-flex align-items-center">
              <MazSelect v-model="newsForm.Category" :options="PartOptions" placeholder="Select Category"
                class="mb-3 w-100" />
            </div>
            <div class="col-4 d-flex align-items-center">
              <MazInput v-model="newsForm.Topic" placeholder="Topic" class="mb-3 w-100" />
            </div>

            <div class="col-3 d-flex align-items-center">
              <MazSelect v-model="newsForm.Prioritization" :options="PrioritizationOptions"
                placeholder="Select Prioritization" search class="mb-3 w-100" />
            </div>
            <!-- <div class="col-2" v-if="newsForm.Category === 'Top News'">
              <input class="form-check-input" type="checkbox" v-model="newsForm.Hold"
                :disabled="TotalHold >= 10 && !newsForm.Hold">
              <p>Hold</p>
            </div> -->
          </div>
        </div>
        <div class="form-group">
          <MazInput v-model="newsForm.Title" placeholder="Title" class="mb-3 w-100" />
        </div>
        <div class="form-group">
          <MazInput v-model="newsForm.Content" placeholder="Content" class="mb-3 w-100" />
        </div>
        <div class="form-group">
          <label>Detail:</label>
          <quill-editor v-model="newsForm.Detail" :options="editorOptions" />
        </div>
        <div class="form-group row">
          <div class="col d-flex align-items-center">
            <label class="col-5">Cover Image:</label>
            <div class="col-7">
              <b-form-file v-model="newsForm.Images" accept="image/*" />
            </div>
          </div>
          <div class="col d-flex align-items-center">
            <label class="col-4">File:</label>
            <div class="col-8">
              <b-form-file v-model="newsForm.File" accept=".docx,.xlsx,.pdf,.pptx, .jpg, .png, .gif" />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col d-flex align-items-center">
            <MazInput v-model="newsForm.Link" placeholder="Link" class="w-100" />
          </div>
          <div class="col d-flex align-items-center">
            <MazPicker v-model="newsForm.Showing" :format="`YYYY-MM-DD`" :formatted="`YYYY-MM-DD`"
              placeholder="Showing date" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col d-flex align-items-center">
            <MazSelect v-model="newsForm.Department" :options="devOptions" placeholder="Select Departments" search
              multiple class="w-100" />
          </div>
          <div class="col d-flex align-items-center">
            <MazInput v-model="computedAuthor" placeholder="Author" class="w-100" readonly />
          </div>
        </div>
        <!-- Show loading spinner or progress bar when saving -->
        <div v-if="loading" class="d-flex justify-Detail-center my-3">
          <!-- Simple loading spinner -->
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <!-- Modal Footer -->
        <div class="modal-footer">
          <button class="btn btn-success" @click="openConfirmModal" :disabled="loading">Save</button>
          <button class="btn btn-secondary" @click="closeModal" :disabled="loading">Cancel</button>
        </div>
      </div>
    </div>

    <!-- Modal HTML -->
    <div class="modal-confirm" v-if="showConfirmModal" tabindex="-1">
      <div class="modal-confirm-Detail">
        <div class="modal-confirm-header flex-column">
          <div class="icon-box" style="border: 3px solid dodgerblue;">
            <div class="succes succes-animation icon-top" style="color: dodgerblue;">
              <i class="fa fa-plus"></i>
            </div>
          </div>
          <h4 class="modal-title w-100">Are you sure?</h4>
        </div>
        <div class="modal-confirm-body">
          <p>Do you want to save this news?</p>
        </div>
        <div class="modal-confirm-footer justify-Detail-center">
          <button class="btn btn-info" @click="confirmSaveNews">Save</button>
          <button class="btn btn-secondary" @click="closeConfirmModal" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>

    <!-- Modal Confirm Delete -->
    <div class="modal-confirm" v-if="showConfirmDeleteModal" tabindex="-1">
      <div class="modal-confirm-Detail">
        <div class="modal-confirm-header flex-column">
          <div class="icon-box">
            <i class="fa fa-trash" style="color: brown;"></i>
          </div>
          <h4 class="modal-title w-100">Confirm Delete</h4>
        </div>
        <div class="modal-confirm-body">
          <p>Are you sure you want to delete this news? This action cannot be undone.</p>
        </div>
        <div class="modal-confirm-footer justify-Detail-center">
          <button class="btn btn-danger" @click="confirmDeleteNews">Delete</button>
          <button class="btn btn-secondary" @click="closeConfirmDeleteModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { db, storage } from '../../../db'
import firebase from 'firebase/app';
import moment from 'moment'
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.snow.css' // for snow theme

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      showConfirmDeleteModal: false,
      showConfirmModal: false,
      loading: false,
      isEditing: false,
      searchQuery: "",
      showModal: false,
      TotalHold: 0,
      newsForm: {
        id: null,
        Category: "",
        Topic: "",
        Hold: false,
        Title: "",
        Content: "",
        Images: null,
        ImagesOld: "",
        File: null,
        FileOld: "",
        Detail: "",
        Showing: "",
        Link: null,
        Department: [],
        Author: "",
        Prioritization: "",
        createdAt: "",
        updatedAt: "",
      },
      AuthorOptions: [],
      News: [],
      currentPage: 1,
      itemsPerPage: 14,
      columns: [
        {
          label: 'No.',
          field: 'index',
           width: '100px'
        },
        {
          label: 'Category',
          field: 'Category',
          width: '130px'
        },
        {
          label: 'Title',
          field: 'title',
          width: '250px'
        },
        {
          label: 'Content',
          field: 'content',
           width: '260px'
        },
        {
          label: 'Department',
          field: 'department',
          width: '250px'
        },
        {
          label: 'Author',
          field: 'Author',
          width: '200px'
        },
        {
          label: 'UpdatedAt',
          field: 'updatedAt',
          width: '140px'
        },
      ],
      PartOptions: [
        { value: 'Top News', label: 'Top News' },
        { value: 'Other News', label: 'Other News' },
      ],
      devOptions: [
        { value: 'DIR', label: 'DIR' },
        { value: 'MNG', label: 'MNG' },
        { value: 'DR', label: 'DR' },
        { value: 'ADMIN', label: 'ADMIN' },
        { value: 'ASST', label: 'ASST' },
        { value: 'S-FIN', label: 'S-FIN' },
        { value: 'FIN', label: 'FIN' },
        { value: 'DEV', label: 'DEV' },
        { value: 'PUR', label: 'PUR' },
        { value: 'AR', label: 'AR' },
        { label: 'HIB', value: 'HIB' },
        { label: 'IB', value: 'IB' },
        { label: 'HRN', value: 'HRN' },
        { value: 'RN', label: 'RN' },
        { value: 'DRN', label: 'DRN' },
        { value: 'PNS', label: 'PNS' },
        { label: 'PMD', value: 'PMD' },
        { label: 'HPH', value: 'HPH' },
        { label: 'DPH', value: 'DPH' },
        { label: 'PH', value: 'PH' },
        { label: 'MT', value: 'MT' },
        { label: 'WA', value: 'WA' },
        { label: 'DV', value: 'DV' },
        { label: 'HK', value: 'HK' },
        { label: 'KT', value: 'KT' },
        { label: 'BF', value: 'BF' },
        { label: 'HOP', value: 'HOP' },
        { value: 'All', label: 'All' }

      ],
      PrioritizationOptions: [
        { value: 'CRITICAL', label: 'CRITICAL' },
        { value: 'IMPORTANT', label: 'IMPORTANT' },
        { value: 'GENERAL', label: 'GENERAL' },
        { value: 'REFERENCE', label: 'REFERENCE' },
      ],
      editorDetail: '',
      editorOptions: {
        theme: 'snow',
      }
    }
  },
  mounted() {
    this.fetchNews()
  },
  computed: {
    AuthorLogin() {
      const userName = this.$store.getters.user.displayName;
      return userName;
    },
    computedAuthor() {
      return this.isEditing ? this.newsForm.Author : this.AuthorLogin;
    },
    filteredUNews() {
      if (!this.searchQuery) return this.News;
      return this.News.filter((News) => {
        const category = News.Category ? News.Category.toLowerCase() : '';
        const title = News.Title ? News.Title.toLowerCase() : '';
        const Content = News.Content ? News.Content.toLowerCase() : '';
        const department = News.Department ? News.Department.map(dept => dept.label.toLowerCase()).join(' ') : '';
        const author = News.Author ? News.Author.toLowerCase() : '';
        const query = this.searchQuery.toLowerCase();

        return (
          category.includes(query) ||
          title.includes(query) ||
          Content.includes(query) ||
          department.includes(query) ||
          author.includes(query)
        );
      });
    },
    totalPages() {
      return Math.ceil(this.filteredUNews.length / this.itemsPerPage);
    },
  },
  watch: {
    searchQuery() {
      this.currentPage = 1;
    },
    News: {
      handler() {
        this.currentPage = 1;
      },
      deep: true,
    },
  },
  methods: {
    fetchNews() {
      db.collection("Ft_announcement")
        .orderBy('createdAt', 'desc')
        .get()
        .then((querySnapshot) => {
          this.News = [];
          this.TotalHold = 0; // ตัวแปรเก็บผลรวมของ Hold
          querySnapshot.forEach((doc) => {
            let newsData = doc.data();

            // แปลง createdAt เป็น "YYYY-MM-DD HH:mm"
            if (newsData.createdAt && typeof newsData.createdAt.toDate === "function") {
              newsData.createdAt = moment(newsData.createdAt.toDate()).format("YYYY-MM-DD HH:mm");
            }

            // แปลง Showing เป็น "YYYY-MM-DD"
            if (newsData.Showing && typeof newsData.Showing.toDate === "function") {
              newsData.Showing = moment(newsData.Showing.toDate()).format("YYYY-MM-DD");
              //console.log(newsData.Showing); // ตรวจสอบค่าที่แปลง
            }
            // แปลง updatedAt เป็น "YYYY-MM-DD HH:mm"
            if (newsData.updatedAt && typeof newsData.updatedAt.toDate === "function") {
              newsData.updatedAt = moment(newsData.updatedAt.toDate()).format("YYYY-MM-DD HH:mm");
            }
            // นับค่า Hold ถ้ามีอยู่
            if (newsData.Hold && !isNaN(newsData.Hold)) {
              this.TotalHold += Number(newsData.Hold);
            }

            // เก็บข้อมูลที่แปลงแล้วใน this.News
            this.News.push({
              ...newsData,
              ImagesOld: newsData.Images, // แสดงผล
              FileOld: newsData.File
            });
          });
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });
    },
    truncateHtml(content, maxLength) {
      let div = document.createElement("div");
      div.innerHTML = content;
      let text = div.innerText || div.textContent;
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    async uploadImage(file, author) {
      if (!file) {
        alert("กรุณาเลือกไฟล์รูปภาพ!");
        return null;
      }
      else {
        // สร้างชื่อไฟล์ใหม่: YYYYMMDDTHHmm_Author
        const timestamp = moment().format("YYYYMMDDTHHmm");
        const fileExtension = file.name.split('.').pop(); // ดึงนามสกุลไฟล์
        const authorName = author ? author.replace(/\s+/g, "_") : "Unknown"; // แทนที่ช่องว่างเป็น "_"
        const newFileName = `${timestamp}_${authorName}.${fileExtension}`;
        // อัปโหลดไฟล์ไปยัง Firebase Storage
        const storageRef = storage.ref(`Ft_announce/images/${newFileName}`);
        const uploadTask = await storageRef.put(file);

        // รับ URL ของไฟล์ที่อัปโหลดสำเร็จ
        const downloadURL = await uploadTask.ref.getDownloadURL();
        //console.log(downloadURL);
        return downloadURL;
      }
    },
    async uploadFile(fileData, author) {
      if (!fileData) {
        alert("กรุณาเลือกไฟล์!");
        return null;
      }
      else {
        // สร้างชื่อไฟล์ใหม่: YYYYMMDDTHHmm_Author
        const timestamp = moment().format("YYYYMMDDTHHmm");
        const fileExtension = fileData.name.split('.').pop(); // ดึงนามสกุลไฟล์
        const authorName = author ? author.replace(/\s+/g, "_") : "Unknown"; // แทนที่ช่องว่างเป็น "_"
        const newFileName = `${timestamp}_${authorName}.${fileExtension}`;
        // อัปโหลดไฟล์ไปยัง Firebase Storage
        const storageRef = storage.ref(`Ft_announce/file/${newFileName}`);
        const uploadTask = await storageRef.put(fileData);
        // รับ URL ของไฟล์ที่อัปโหลดสำเร็จ
        const downloadURL = await uploadTask.ref.getDownloadURL();
        // console.log(`FileUrl`,downloadURL);
        return downloadURL;
      }
    },
    extractPathFromUrl(url) {
      try {
        const startIndex = url.indexOf('o/') + 2;
        const endIndex = url.indexOf('?');
        const encodedFilePath = url.substring(startIndex, endIndex);
        return decodeURIComponent(encodedFilePath);
      } catch (error) {
        console.error("Invalid URL:", error);
        return null; // คืนค่า null หาก URL ไม่ถูกต้อง
      }
    },
    // ฟังก์ชันลบไฟล์โดยใช้ URL
    async deleteFileByUrl(url) {
      try {
        const filePath = this.extractPathFromUrl(url);
        if (filePath) {
          const fileRef = firebase.storage().ref(filePath);
          await fileRef.delete();
        } else {
          console.error('Invalid file path:', filePath);
        }
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    },
    // ฟังก์ชันที่เปิด modal ยืนยันการบันทึก
    confirmSaveNews() {
      this.saveNews();  // เรียกใช้ฟังก์ชันบันทึกข่าว
      this.closeConfirmModal();  // ปิด modal
    },
    // ฟังก์ชันที่เปิด modal ยืนยัน
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    // ฟังก์ชันปิด modal ยืนยัน
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    openConfirmDelete() {
      this.showConfirmDeleteModal = true;
    },
    closeConfirmDeleteModal() {
      this.showConfirmDeleteModal = false;
    },
    async confirmDeleteNews() {
      try {
        if (!this.newsForm || !this.newsForm.id) {
          alert("Cannot delete. News data is missing.");
          return;
        }

        if (this.newsForm.ImagesOld) await this.deleteFileByUrl(this.newsForm.ImagesOld);
        if (this.newsForm.FileOld) await this.deleteFileByUrl(this.newsForm.FileOld);

        // ลบข้อมูลจาก Firestore
        await db.collection('Ft_announcement').doc(this.newsForm.id).delete();
        // ปิด Modal
        this.showConfirmDeleteModal = false;
        this.showModal = false;
        // โหลดข่าวใหม่
        await this.fetchNews();
      } catch (error) {
        console.error("Error deleting news:", error);
        alert("Failed to delete news. Please try again.");
      }
    },
    openAddModal() {
      this.isEditing = false; // กำหนดว่าไม่ใช่การแก้ไข
      this.newsForm = {
        id: null,
        Category: "",
        Topic: "",
        // Hold: false, // ยังไม่ได้ใช้
        Title: "",
        Content: "",
        Images: null,
        File: null,
        Detail: "",
        Showing: moment().format("YYYY-MM-DD"), // ตั้งค่าวันที่ปัจจุบัน (วันนี้) เป็นค่าเริ่มต้น
        Link: null,
        Department: [],
        Author: "",
        Prioritization: "",
        createdAt: "",
        updatedAt: "",
      };
      this.showModal = true; // เปิด Modal
    },
    async saveNews() {

      const formattedDepartments = Array.isArray(this.newsForm.Department)
        ? this.newsForm.Department.map(department => ({
          value: department,
          label: department
        }))
        : [];

      const isFormValid = this.newsForm.Category && this.newsForm.Topic && this.newsForm.Title && this.newsForm.Prioritization &&
        this.newsForm.Showing && formattedDepartments.length > 0;

      if (!isFormValid) {
        alert('กรุณากรอกข้อมูลให้ครบถ้วนก่อนบันทึก!');
        return;
      }
      this.loading = true;
      try {
        let imageUrl = this.newsForm.Images || null;
        let fileUrl = this.newsForm.File || null;

        // ถ้ามีการเลือกไฟล์รูปใหม่
        if (this.newsForm.Images) {
          imageUrl = await this.uploadImage(this.newsForm.Images, this.AuthorLogin);
        } else if (this.newsForm.ImagesOld) {
          // ใช้รูปเดิม (ถ้ามี)
          imageUrl = this.newsForm.ImagesOld;
        }

        // ถ้ามีการเลือกไฟล์ใหม่
        if (this.newsForm.File) {
          fileUrl = await this.uploadFile(this.newsForm.File, this.AuthorLogin);
        } else if (this.newsForm.FileOld) {
          // ใช้ไฟล์เดิม (ถ้ามี)
          fileUrl = this.newsForm.FileOld;
        }
        // ตรวจสอบวันที่ในรูปแบบที่ถูกต้อง
        const createdAtMoment = moment(this.newsForm.createdAt, "YYYY-MM-DDTHH:mm", true);
        const formattedDate = createdAtMoment.isValid() ? createdAtMoment.toDate() : new Date();

        // เตรียมข้อมูลข่าว
        const newsData = {
          Category: this.newsForm.Category,
          Topic: this.newsForm.Topic,
          Prioritization: this.newsForm.Prioritization,
          // Hold: CheckHold,
          Title: this.newsForm.Title,
          Content: this.newsForm.Content,
          Images: imageUrl,
          File: fileUrl,
          Detail: this.newsForm.Detail,
          Showing: this.newsForm.Showing,
          Link: this.newsForm.Link || "",
          Department: formattedDepartments,
          Author: this.AuthorLogin || '',
        };

        // ตรวจสอบค่า Category และการตั้งค่า Hold
        // let CheckHold = false;
        // if (newsData.Category === 'Top News') {
        //   CheckHold = this.newsForm.Hold;
        // }
        // newsData.Hold = CheckHold;  // นำค่า CheckHold ไปตั้งค่าที่ newsData

        if (this.isEditing) {
          // อัปเดตข่าว
          newsData.updatedAt = formattedDate; // เพิ่ม timestamp แก้ไขล่าสุด
          await db.collection("Ft_announcement").doc(this.newsForm.id).update(newsData);
          alert("News updated successfully!");
          await this.fetchNews();
        } else {
          // เพิ่มข่าวใหม่
          newsData.createdAt = formattedDate; // เพิ่ม timestamp สร้างข่าว
          newsData.updatedAt = formattedDate;
          newsData.Comments = [];
          newsData.views = 0;
          newsData.readers=[];

          const docRef = await db.collection("Ft_announcement").add(newsData);
          await db.collection("Ft_announcement").doc(docRef.id).update({ id: docRef.id }); // อัปเดต ID ในฐานข้อมูล
          alert("News added successfully!");
          await this.fetchNews();
        }
      } catch (error) {
        alert('❌ เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่!');
      } finally {
        this.loading = false;
        this.closeModal();
        this.currentPage = 1;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    editNews(news) {
      this.isEditing = true;
      const departmentValues = news.Department.map(department => department.value);
      this.newsForm = {
        id: news.id,
        Category: news.Category || "",
        Topic: news.Topic || "",
        Prioritization: news.Prioritization || "Low",
        Hold: news.Hold || false,
        Title: news.Title || "",
        Content: news.Content || "",
        Images: null,
        ImagesOld: news.ImagesOld || "",
        File: null,
        FileOld: news.FileOld || "",
        Detail: news.Detail || "",
        Showing: news.Showing || moment().format("YYYY-MM-DD"), // ตั้งค่าวันที่ปัจจุบัน (วันนี้) เป็นค่าเริ่มต้น
        Link: news.Link || "",
        Department: departmentValues || [],
        Author: this.AuthorLogin || "",
      };
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    getColorDepartment(value) {
      const colors = {
        'DIR': '#2F6EBA', //*
        'MNG': '#4D96FF',//*
        'DR': '#274E13',//
        'ADMIN': '#4D96FF',//*
        'ASST': '#4D96FF',//*
        // 'S-FIN': '#FFB74D',//
        'FIN': '#FFB74D',//
        'DEV': '#FFA500',//*
        'PUR': '#FFA500',//*
        'AR': '#E64A19',//
        // 'HIB': '#4FC3F7',//3C78D8
        'IB': '#4FC3F7',//
        // 'HRN': '#388E3C',//
        'RN': '#388E3C',//
        // 'DRN': '#388E3C',//
        'PMD': '#388E3C',//
        "PNS": "#4FC3F7",// Partnership
        // 'HPH': '#8BC34A',//
        // 'DPH': '#8BC34A',//
        'PH': '#8BC34A',//
        'MT': '#C5E1A5',//* Colors.lightBlue.shade200 
        'WA': '#FFECB3',//
        'DV': '#B71C1C',///
        'HK': '#F9A825',///
        'KT': '#795548',//* Colors.brown
        "BF": "#FFCA28",//
        "HOP": "#01579B",//
        'All': '#F44336',///
      };
      return colors[value] || '#cccccc'; // Default Grey
    },
    getColor(value) {
      const importanceLevels = {
        'CRITICAL': '#dc3545',
        'IMPORTANT': '#FFA500',
        'GENERAL': '#A0A0A0', //
        'REFERENCE': '#none',
      };
      // ตรวจสอบว่า value มีใน importanceLevels หรือไม่
      return importanceLevels[value] || '#cccccc'; // ถ้าไม่มีให้ใช้สีเทาเป็นค่า default
    },
  },
};
</script>

<style lang="scss" scoped>
.ManageNewIndex {
  font-family: 'Arial', 'Sarabun' !important;
  border-radius: 5px;
  color: white !important;
  width: 95vw !important;
  height: auto;
  min-height: 100vh;

  .button-tooltip {
      font-size: 10px;
      font-weight: bold;
      border-radius: 5px;
      margin: 5px 5px;
      padding: 5px 5px;
      background-color: transparent !important;
      color: rgb(0, 0, 0);
      border: none;
    }

  ::v-deep th {
      font-size: 16px;
      background-color: #0b97e5;
      border: 1px solid rgb(139, 135, 135) !important;
      color: rgb(255, 255, 255);
      padding: 20px;
      text-align: center;
  }

  ::v-deep td {
      font-weight: 500;
      font-size: 15px;
      border: 1px solid rgb(139, 135, 135) !important;
      color: rgb(0, 0, 0);
      padding: 5px;
      text-align: center;
  }


  /* Modal */
  .modal {
    color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1040;
    padding-top: 20px;
  }

  .modal-header {
    position: relative;
  }

  .modal-confirm-body {
    color: #000;
  }

  .delete-btn {
    position: absolute;
    top: 1;
    right: 0;
    margin: 5px 10px;
    padding: 5px 10px;
  }

  .modal-title {
    color: #000;
    height: 70px;
  }

  .modal-Detail {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 800px;
    max-height: 100vh;
    overflow-y: auto;
  }

  .modal-footer {
    height: 50px;

    button {
      width: 100px;
    }
  }

  button {
    cursor: pointer;
  }

  /* Quill Editor */
  .ql-editor img {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
  }

  .quill-editor {
    height: 300px;
    max-height: 280px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }


  .ql-toolbar {
    position: fixed;
    top: 0;
    z-index: 10;
    background: white;
    border-bottom: 1px solid #ddd;
  }

  .ql-container {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 400px;
  }


  /* Confirm Modal */
  .modal-confirm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    padding: 0 20px;
  }

  .modal-confirm-Detail {
    background-color: white;
    border-radius: 10px;
    width: 400px;
    padding: 50px;
    text-align: center;
  }

  .modal-confirm-header {
    position: relative;
  }

  .icon-box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid #79aaa6;
    text-align: center;
    margin: 0 auto 20px;
  }

  .icon-box i {
    font-size: 46px;
    margin-top: 13px;
  }

  .modal-title {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .modal-confirm-footer {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  .modal-confirm-footer .btn {
    width: 100px;
    margin: 0 10px;
  }
}
</style>
