<template>
  <div v-if="!newsOther || newsOther.length === 0" class="Announcement-mini">
    <div class="loader-container">
      <div class="loader-ring"></div>
      <span>loading...</span>
    </div>
  </div>
  <div v-else class="Announcement-mini">
    <div class="row">
      <div v-for="(item, i) in paginatedItems" :key="i" class="col-12 col-sm-6 col-md-4 col-lg-4">
        <div class="card-Other-News">
          <div class="card-img" @click="openModal(item)">
            <img v-if="item.Images" :src="item.Images ? item.Images : require('./images/NoImg.svg')" :alt="item.Title"
              class="images-class" />
          </div>
          <div class="card-body">
            <div class="card-head">
              <div v-if="item.Prioritization == 'IMPORTANT'">
                  <i class="fa fa-star" style="color: #ffa500ff; margin-right: 5px;  font-size: 25px;" aria-hidden="true"></i>
              </div>
              <div v-if="item.Prioritization == 'GENERAL'">
                <i class="fa fa-graduation-cap"  style="color: #3f51b5; margin-right: 5px; font-size: 25px;" aria-hidden="true"></i>
              </div>
              <div v-if="item.Prioritization == 'CRITICAL'">
                <i class="fa fa-exclamation-triangle"  style="color: #dc3545ff; margin-right: 5px; font-size: 25px;" aria-hidden="true"></i>
              </div>
              <div class="card-topic" @click="openModal(item)" :style="{ color: getColor(item.Prioritization) }">
                {{ item.Topic }}
              </div>
              <button @click="toggleBookmark(item.id)" style="background: none; border: none;" class="ml-auto">
                <span v-if="item.Bookmark && Array.isArray(item.Bookmark) && item.Bookmark.includes(users.displayName)"
                  class="bookmarked">
                  <i class="fa fa-bookmark" aria-hidden="true"></i>
                </span>
                <span v-else class="not-bookmarked">
                  <i class="fa fa-bookmark" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="card-title" v-html="addIndentAndTruncate(item.Title, 60)" @click="openModal(item)"></div>
            <div class="card-department">
              <div v-if="item.Department.length > 0">
                <button v-for="(dept, index) in item.Department.slice(0, 3)" :key="index" class="btn btn-sm mx-1"
                  :style="{
                    backgroundColor: getColorDepartment(dept.value),
                    color: '#fff',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    padding: '5px 5px',
                    marginBottom: '2px'
                  }">
                  {{ dept.label }}
                </button>
              </div>
            </div>
            <hr style="margin: 10px 0;" />
            <div class="card-footer-Other">
              <div class="footer-left">
                <small class="time-footer">
                  <small>{{ timeSince(item.updatedAt) }}</small>
                </small>
                <template v-if="item?.Avatar" style="margin-left:10px">
                  <img :src="item.Avatar" alt="Author Avatar" class="footer-avatar" />
                </template>
                <template v-else>
                  <div class="footer-avatar-placeholder" style="margin-left:10px">
                    {{ item?.Author?.charAt(0).toUpperCase() || "?" }}
                  </div>
                </template>
                <div class="footer-author-name">{{ item?.Author || "Unknown" }}</div>
              </div>
              <div class="footer-right">
                <div class="footer-likes">
                  <i class="likes-count fa fa-user" aria-hidden="true"></i>
                  <p>{{ item?.views || 0 }}</p>
                </div>
                <b-button v-b-toggle="`collapse-${i}`" variant="primary" class="mx-1 footer-btn">
                  <i class="fa fa-comments" aria-hidden="true"></i>
                </b-button>
              </div>
            </div>
          </div>
          <b-collapse :id="`collapse-${i}`" class="mt-3" style="overflow-x: auto;">
            <b-card style="height: auto; width: 100%;  border: none; margin-top: -15px;">
              <div v-for="(comment, index) in item.Comments" :key="index" class="comment-section">
                <div class="row">
                  <div class="col-1">
                    <img :src="comment.userAvatar" class="comment-avatar" alt="User Avatar" />
                  </div>
                  <div class="col-10">
                    <div class="comment-header d-flex align-items-center">
                      <div class="ml-1">
                        <span class="user-name">{{ comment.userName }}</span>
                        <span class="comment-time ml-3">{{ timestampSince(comment.createdAt) }}</span>
                      </div>
                    </div>
                    <p class="comment-text">{{ comment.text }}</p>
                  </div>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </div>
      </div>

    </div>
    <div v-if="totalPagesmini > 1" style="margin-top: 10px;">
      <ul class="pagination">
        <li v-if="currentPageMini > 1">
          <a href="#" @click.prevent="goToPage(currentPageMini - 1)">Prev</a>
        </li>
        <li v-for="page in totalPagesmini" :key="page">
          <a href="#" :class="{ active: currentPageMini === page }" @click.prevent="goToPage(page)">
            {{ page }}
          </a>
        </li>
        <li v-if="currentPageMini < totalPagesmini">
          <a href="#" @click.prevent="goToPage(currentPageMini + 1)">Next</a>
        </li>
      </ul>
    </div>
    <!-- Modal -->
    <div v-if="showModal" class="modal" @click.self="closeModal">
      <ModelContent :modalContent="modalContent" @closeModal="closeModal" />
    </div>
  </div>
</template>

<script>
import ModelContent from './modal.vue'
import firebase from 'firebase/app';
import moment from 'moment'
import { db, storage } from '../../../db'
export default {
  components: {
    ModelContent,
  },
  props: {
    newsOther: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isFullScreen: false,
      item: [],
      currentPageMini: 1,
      itemsPerPage: 12,
      showModal: false,
      modalContent: {},
      loading: false,
      isBookmarked: false,
      isCollapsed: {},
      newCommentText: "",
    };
  },

  watch: {
    showModal(newVal) {
      if (newVal) {
        this.trackAnnouncementView()
      }
    },
    newsOther(newsOther) {
      this.item = newsOther;
      this.currentPageMini = 1;
      this.loading = newsOther.length === 0;
    },
    isBookmarked() {
      const userId = this.$store.getters.user.displayName;
      return this.item.bookmark && Array.isArray(this.item.bookmark) &&
        this.item.bookmark.includes(userId);
    },
  },
  computed: {
    totalPagesmini() {
      return Math.max(1, Math.ceil(this.item.length / this.itemsPerPage));
    },
    paginatedItems() {
      const start = (this.currentPageMini - 1) * this.itemsPerPage;
      return this.item.slice(start, start + this.itemsPerPage);
    },

    users() {
      return this.$store.getters.user;
    }
  },
  methods: {

    async toggleBookmark(articleId) {
      try {
        const userId = this.users.displayName;
        const index = this.item.findIndex(item => item.id === articleId); // ใช้กับ this.item (ตัวเต็ม)

        if (index === -1) return;

        const item = this.item[index];
        const docRef = db.collection('Ft_announcement').doc(articleId);
        const docSnapshot = await docRef.get();

        if (!docSnapshot.exists) {
          // ถ้ายังไม่มี document ให้สร้างใหม่
          await docRef.set({ Bookmark: [userId] });
          this.$set(this.item[index], 'Bookmark', [userId]);
        } else {
          const currentBookmarks = docSnapshot.data().Bookmark || [];

          if (currentBookmarks.includes(userId)) {
            // ถ้ากำลังกดเอา Bookmark ออก
            await docRef.update({
              Bookmark: firebase.firestore.FieldValue.arrayRemove(userId),
            });
            const updated = currentBookmarks.filter(uid => uid !== userId);
            this.$set(this.item[index], 'Bookmark', updated);
          } else {
            // ถ้ากำลังกด Bookmark เพิ่ม
            await docRef.update({
              Bookmark: firebase.firestore.FieldValue.arrayUnion(userId),
            });
            const updated = [...currentBookmarks, userId];
            this.$set(this.item[index], 'Bookmark', updated);
          }
        }
      } catch (error) {
        console.error("Error toggling bookmark:", error);
      }
    },

    // ฟังก์ชันการเพิ่ม userId ลงใน Bookmark
    async addBookmarkToAnnouncement(articleId, userId) {
      try {
        const docRef = db.collection('Ft_announcement').doc(articleId);
        const docSnapshot = await docRef.get();

        // ถ้าเอกสารไม่พบ ให้สร้างใหม่
        if (!docSnapshot.exists) {
          await docRef.set({
            Bookmark: [userId], // ถ้าไม่มีฟิลด์ Bookmark ให้สร้างใหม่และเพิ่ม userId
          });
        } else {
          // ถ้าเอกสารมีอยู่แล้ว ให้เพิ่ม userId ลงใน array
          await docRef.update({
            Bookmark: firebase.firestore.FieldValue.arrayUnion(userId), // เพิ่ม userId เข้าไปใน array
          });
        }
      } catch (error) {
        console.error("Error adding bookmark to Ft_announcement:", error);
      }
    },

    // ฟังก์ชันการลบ userId จาก Bookmark
    async removeBookmarkFromAnnouncement(articleId, userId) {
      try {
        const docRef = db.collection('Ft_announcement').doc(articleId);
        await docRef.update({
          Bookmark: firebase.firestore.FieldValue.arrayRemove(userId), // ลบ userId ออกจาก array
        });
      } catch (error) {
        console.error("Error removing bookmark from Ft_announcement:", error);
      }
    }
    ,
    async trackAnnouncementView() {
      try {
        const displayName = this.$store.getters.user.displayName
        const docId = this.modalContent?.id
        if (!docId || !displayName) return

        const docRef = firebase.firestore().collection('Ft_announcement').doc(docId)
        const docSnap = await docRef.get()

        if (docSnap.exists) {
          const data = docSnap.data()
          const readers = data.readers || []
          const alreadyRead = readers.some(r => r.name === displayName)

          if (!alreadyRead) {
            const newReader = {
              name: displayName,
              timestamp: moment(new Date()).format("YYYYMMDDTHHmm")
            }

            await docRef.update({
              readers: firebase.firestore.FieldValue.arrayUnion(newReader),
              views: firebase.firestore.FieldValue.increment(1)
            })
          }
        }
      } catch (err) {
        console.error("Error tracking view:", err)
      }
    },
    getPrioritizationImage(prioritization) {
      try {
        return require(`./images/${prioritization}.svg`);
      } catch (e) {
        return require('./images/NoImg.svg'); // fallback ถ้าไม่เจอ
      }
    },
    submitComment() {
      if (this.newCommentText) {
        this.comments.push({
          userAvatar: "https://example.com/avatar1.jpg",
          userName: "New User",
          timeAgo: "Just now",
          text: this.newCommentText,
          replies: [],
        });
        this.newCommentText = "";
      }
    },

    openComments(item) {
      this.$set(this.isCollapsed, item.id, true);
    },
    // Close modal for a specific card
    closeComments(index) {
      this.$set(this.isCollapsed, index, false);
    },

    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
    },
    openModal(item) {
      this.modalContent = item;
      this.showModal = true;
    },
    closeModal() {
      this.isFullScreen = false;
      this.showModal = false;
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPagesmini) {
        this.currentPageMini = page;
      }
    },
    truncateHtml(content, maxLength) {
      let div = document.createElement("div");
      div.innerHTML = content;
      let text = div.innerText || div.textContent;
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    addIndentAndTruncate(content, maxLength) {
      const indent = '&nbsp;'.repeat(5); // ย่อหน้า 5 ช่อง
      const truncated = this.truncateHtml(content, maxLength);
      return indent + truncated;
    },
    getColor(value) {
      const importanceLevels = {
        'CRITICAL': '#dc3545',
        'IMPORTANT': '#FFA500',
        'GENERAL': '#A0A0A0', //
        'REFERENCE': '#none',
      };
      // ตรวจสอบว่า value มีใน importanceLevels หรือไม่
      return importanceLevels[value] || '#cccccc'; // ถ้าไม่มีให้ใช้สีเทาเป็นค่า default
    },
    getColorDepartment(value) {
      const colors = {
        'DIR': '#2F6EBA', //*
        'MNG': '#4D96FF',//*
        'DR': '#274E13',//
        'ADMIN': '#4D96FF',//*
        'ASST': '#4D96FF',//*
        // 'S-FIN': '#FFB74D',//
        'FIN': '#FFB74D',//
        'DEV': '#FFA500',//*
        'PUR': '#FFA500',//*
        'AR': '#E64A19',//
        // 'HIB': '#4FC3F7',//3C78D8
        'IB': '#4FC3F7',//
        // 'HRN': '#388E3C',//
        'RN': '#388E3C',//
        // 'DRN': '#388E3C',//
        'PMD': '#388E3C',//
        "PNS": "#4FC3F7",// Partnership
        // 'HPH': '#8BC34A',//
        // 'DPH': '#8BC34A',//
        'PH': '#8BC34A',//
        'MT': '#C5E1A5',//* Colors.lightBlue.shade200 
        'WA': '#FFECB3',//
        'DV': '#B71C1C',///
        'HK': '#F9A825',///
        'KT': '#795548',//* Colors.brown
        "BF": "#FFCA28",//
        "HOP": "#01579B",//
        'All': '#F44336',///
      };
      return colors[value] || '#cccccc'; // Default Grey
    },
    getTextColor(bgColor) {
      // คำนวณความสว่างของสีพื้นหลัง
      let r = parseInt(bgColor.substring(1, 3), 16);
      let g = parseInt(bgColor.substring(3, 5), 16);
      let b = parseInt(bgColor.substring(5, 7), 16);
      let brightness = (r * 299 + g * 587 + b * 114) / 1000;

      return brightness > 125 ? '#000000' : '#FFFFFF'; // ถ้าสีพื้นหลังสว่างให้ใช้สีดำ, ถ้าสีพื้นหลังเข้มให้ใช้สีขาว
    },
    timestampSince(dateStr) {
      // แปลง '20250409T1702' => '2025-04-09T17:02:00'
      const formatted = dateStr.replace(
        /^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})$/,
        '$1-$2-$3T$4:$5:00'
      );

      const past = new Date(formatted);
      const now = new Date();

      const seconds = Math.floor((now - past) / 1000);

      let interval = seconds / 31536000;
      if (interval >= 1) return Math.floor(interval) + " ปีที่แล้ว";

      interval = seconds / 2592000;
      if (interval >= 1) return Math.floor(interval) + " เดือนที่แล้ว";

      interval = seconds / 86400;
      if (interval >= 1) return Math.floor(interval) + " วันที่แล้ว";

      interval = seconds / 3600;
      if (interval >= 1) return Math.floor(interval) + " ชั่วโมงที่แล้ว";

      interval = seconds / 60;
      if (interval >= 1) return Math.floor(interval) + " นาทีที่แล้ว";

      return "ไม่กี่วินาทีที่แล้ว";
    }
    ,
    timeSince(dateStr) {
      const past = new Date(dateStr.replace(/-/g, '/'));
      const now = new Date();
      const seconds = Math.floor((now - past) / 1000);
      // คำนวณปี
      let interval = seconds / 31536000;
      if (interval >= 1) {
        return Math.floor(interval) + " ปีที่แล้ว";
      }
      // คำนวณเดือน
      interval = seconds / 2592000;
      if (interval >= 1) {
        return Math.floor(interval) + " เดือนที่แล้ว";
      }
      // คำนวณวัน
      interval = seconds / 86400;
      if (interval >= 1) {
        return Math.floor(interval) + " วันที่แล้ว";
      }
      // คำนวณชั่วโมง
      interval = seconds / 3600;
      if (interval >= 1) {
        return Math.floor(interval) + " ชั่วโมงที่แล้ว";
      }
      // คำนวณนาที
      interval = seconds / 60;
      if (interval >= 1) {
        return Math.floor(interval) + " นาทีที่แล้ว";
      }
      return "ไม่กี่วินาทีที่แล้ว";
    }
  }
};
</script>


<style lang="scss" scoped>
.Announcement-mini {
  padding: 2px;
  font-family: 'Arial', 'Sarabun' !important;

  .news-image-Prioritization {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }

  .comment-section {
    padding: 5px 0; // ลดจาก 5px
  }

  .comment-header {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .comment-avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  .user-name {
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
  }

  .comment-time {
    font-size: 10px;
    color: gray;
    line-height: 1;
  }

  .comment-text {
    margin-top: 3px; // ลดจาก 5px
    font-size: 12px; // ลดขนาดตัวอักษรนิดหน่อย
    line-height: 1.4; // ควบคุมความสูง
    margin-bottom: 6px; // เพิ่มความกระชับ
  }



  .card-Other-News {
    font-family: 'Arial', 'Sarabun' !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid #b6b5b57e;
    overflow: hidden;
    // transition: 0.3s ease-in-out;
    margin-top: 10px;
    height: auto;
    min-height: 400px;
  }

  // .card-Other-News:hover {
  //   transform: translateY(-5px);
  // }

  .card-body {
    margin-top: 5px;
    padding: 18px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-head {
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: -20px;
  }

  .bookmarked i {
    color: #ff8800;
    /* สีทองสำหรับสถานะบันทึก */
  }

  .not-bookmarked i {
    color: #cccccc;
    /* สีเทาสำหรับสถานะไม่บันทึก */
  }

  .card-img {
    position: relative;
    width: 100%;
    height: 220px;
    /* ความสูงคงที่ */
    display: flex;
    justify-content: center;
    /* จัดภาพให้อยู่กลางแนวนอน */
    align-items: center;
    /* จัดภาพให้อยู่กลางแนวตั้ง */
    overflow: hidden;
    border-radius: 2px;
    background-color: #f2f2f2;
    /* กรณีรูปไม่มี */
    padding: 2px;
  }

  .images-class {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* ให้รูปอยู่ในกรอบโดยไม่บิดเบี้ยว */
    border-radius: 10px;
  }

  // .card-img {
  //   width: 100%;
  //   height: 220px;
  //   overflow: hidden;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   background-color: #ffffff;
  // }

  // .images-class {
  //   padding: 10px;
  //   border-radius: 20px;
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  //   /* สำคัญ: ครอบภาพเต็มไม่เพี้ยน */
  // }

  .card-topic {
    font-size: 18px;
    font-weight: bold;
    color: #1637f0;
    overflow: hidden;
    text-overflow: ellipsis;
    // margin-top: -15px;
  }

  ////////////////////card///////////////////////

  .card-department {
    margin-top: 0px;
    width: auto !important;
  }

  .card-title {
    background-color: transparent !important;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    // margin-top: -25px;
    // max-height: 3rem;
    // line-height: 1.5rem;
  }

  .card-content {
    background-color: transparent !important;
    font-size: 12px;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-footer-Other {
    height: 10px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #888;
  }

  .footer-left {
    display: flex;
    align-items: center;
  }

  .footer-right {
    display: flex;
    align-items: center;
  }

  .footer-avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
  }

  .footer-avatar-placeholder {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    margin-right: 5px;
  }

  .footer-author-name {
    font-size: 10px;
    font-weight: bold;
    color: #333;
  }

  .footer-likes {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
  }

  .footer-btn i {
    color: #007bff;
    /* สีฟ้าหรือสีที่คุณต้องการ */
    font-size: 16px;
    /* ขนาดของไอคอน */
  }

  .footer-btn {
    background: none;
    /* ไม่มีพื้นหลัง */
    border: none;
    /* ไม่มีกรอบ */
    cursor: pointer;
    /* เปลี่ยนเป็น cursor ปกติเมื่อ hover */
  }

  .footer-btn:hover i {
    color: #0056b3;
    /* เปลี่ยนสีเมื่อ hover */
  }



  //////////////////////////////////////////////////////
  /* เปลี่ยนพื้นหลังของแถบ pagination ให้เป็นสีขาว */
  .pagination {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    backdrop-filter: blur(3px);
    border-radius: 10px;
    padding: 20px;
    gap: 10px;
  }


  .pagination a {
    text-decoration: none;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    font-size: 16px;
  }

  .pagination a:hover {
    background-color: #f0f0f0;
  }

  .pagination .prev,
  .pagination .next {
    font-weight: bold;
  }

  .pagination .page-number {
    background-color: #fff;
  }

  .pagination .active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }

  ////////////////////////////tooltip//////////////////
  .button-tooltip {
    font-size: 10px;
    font-weight: bold;
    border-radius: 5px;
    padding: 6px 5px;
    background-color: transparent !important;
    color: rgb(0, 0, 0);
    border: none;
  }


  ///////////////////////modal////////////////////////////
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(214, 214, 214, 0.829);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: hidden;
  }

  //////////////////////loader////////////////////////////////
  .loader-container {
    margin-top: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #fffdfd;
    width: 100%;
    height: 75%;
    min-height: 450px;
    border-radius: 5px;
    padding: 5px;
  }

  .loader-ring {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    animation: loader-ring 2s linear infinite;
  }

  @keyframes loader-ring {
    0% {
      transform: rotate(0deg);
      box-shadow: 1px 5px 2px #e65c00;
    }

    50% {
      transform: rotate(180deg);
      box-shadow: 1px 5px 2px #18b201;
    }

    100% {
      transform: rotate(360deg);
      box-shadow: 1px 5px 2px #0456c8;
    }
  }

  .loader-ring:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255, 255, 255, .3);
  }

  span {
    color: #737373;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 200px;
    animation: text 3s ease-in-out infinite;
  }

  @keyframes text {
    50% {
      color: black;
    }
  }


  ///////////////////END//////////////////////////
}
</style>
