<template>
  <div class="container-fluid"> 
    <div class="card shadow">
      <div class="card-body" style="min-height: 600px;">
        <div class="d-flex align-items-center">
          <div class="box-bookMark-Statistic d-flex align-items-center justify-content-center">
            <h3 class="box-bookMark-Statistic">Statistic</h3>
          </div>
          <!-- <button class="btn btn-primary ml-auto d-flex align-items-center justify-content-center" @click="loadData">
            <i class="fas fa-sync"></i> Reload Data
          </button> -->
        </div>
        <b-nav  class="mt-2" tabs>
          <b-nav-item :active="$route.path===item.to" :to="item.to" v-for="item in menus" :key="item.name">{{item.name}}</b-nav-item>
        </b-nav>
        <!-- <p v-for="d in data" :key="d.id">{{d.created_at}}</p> -->
        <!-- <router-view :patients="data" :visits="visit_data"></router-view> -->
        <router-view ></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  data() {
    return {
      menus: [
        { name: 'Patient', to: '/statistic' },
        { name: 'Discovery', to: '/discovery' },
        { name: 'Hotel', to: '/hotel' },
        { name: 'Address', to: '/address' },
        { name: 'Demographic', to: '/demographic' },
        { name: 'Register', to: '/register' },
        { name: 'Visit', to:'/visit' },
        { name: 'Time', to: '/time' },
      ],
      data: [],
      visit_data: [],
    }
  },
  mounted() {
    //this.loadData()
  },
  methods: {
    async loadData() {

      this.data = []

      let patientRef = await db
        .collection("Patient")
        .get();
      
      for(let p of patientRef.docs) {
        this.data.push({
          id: p.id,
          ...p.data()
        })
      }
      this.visit_data = []
      let visitRef = await db
        .collection("Visit")
        .get();
      
      for(let p of visitRef.docs) {
        this.visit_data.push({
          id: p.id,
          ...p.data()
        })
      }
    }
  }
}
</script>
<style lang="css">
.box-bookMark-Statistic {
  width: 200px;
  height: 100px;
  background-color: #2F6EBA;
  margin-top: -11px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-bookMark-Statistic h3{
  color: #ffffff;
}
</style>