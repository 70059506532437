<template>
    <div class="Announcement">
        <div class="row">
             <div class="col-12">
                <div class="item "> 
                    <CardTopNews :newsTop="DataTop" />
                    <div style="margin-top: 10px;" v-if=" !Other || Other.length === 0">
                        <b-button v-b-toggle.collapse-1 variant="primary" class="mx-1">Filter</b-button>
                        <b-collapse id="collapse-1" class="mt-2">
                            <b-card>
                                <div class="row">
                                    <div class="col-2" style="padding: 10px;">
                                        <MazSelect v-model="Filter.Prioritization" :options="PrioritizationOptions"
                                            placeholder="Select Prioritization" search class="w-100" />
                                    </div>
                                    <div class="col-3" style="padding: 10px;">
                                        <MazSelect v-model="Filter.Department" :options="devOptions"
                                            placeholder="Select Departments" search multiple class="w-100" />
                                    </div>
                                    <div class="col-3" style="padding: 10px;">
                                        <MazInput v-model="Filter.Topic" placeholder="Topic" class="mb-3 w-100" />
                                    </div>
                                    <div class="col-2" style="padding: 10px;">
                                        <b-button v-if="isFilterActive" variant="primary" class="mb-3"
                                            @click="handleFilter" style="padding: 10px; margin-right:  10px;">
                                            Filter
                                        </b-button>
                                        <b-button v-if="isFilterActive" variant="danger" @click="handleClear"
                                            class="mb-3" style="padding: 10px;">
                                            Clear
                                        </b-button>
                                    </div>
                                </div>
                            </b-card>
                        </b-collapse>
                    </div>
                    <CardOtherNews :newsOther="Other"  />
                </div>
                
          </div>
            <!-- <div class="col-3">
                <div class="item  h-50">
                    <CardBookMark :BookMark="BookMark"/>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { db } from '../../db'
import moment from 'moment'
import CardOtherNews from './Announcement/card-Other-News.vue'
import CardTopNews from "./Announcement/card-Top-News.vue"
// import CardBookMark from "./Announcement/Bookmark.vue"

export default {
    components: {
        CardTopNews,
        CardOtherNews,
        //  CardBookMark,
    },
    data() {
        return {
            News: [],
            DataTop: [],
            Other: [],
            // BookMark:[],
            searchModal: false,
            isFilterOpen: false,
            Filter: {
                Topic: null,
                Prioritization: null,  // กำหนดค่าเริ่มต้นเป็น null หรือค่าที่คุณต้องการ
                Day: '',
                // Day: moment().format("YYYY-MM-DD"), // ตั้งค่าวันที่ปัจจุบัน (วันนี้) เป็นค่าเริ่มต้น,
                Department: [] // กำหนดค่าเริ่มต้นเป็น null หรือค่าที่คุณต้องการ
            },
            PrioritizationOptions: [
                { value: 'CRITICAL', label: 'CRITICAL' },
                { value: 'IMPORTANT', label: 'IMPORTANT' },
                { value: 'GENERAL', label: 'GENERAL' },
                { value: 'REFERENCE', label: 'REFERENCE' }
            ],
            GroupOptions: [
                { value: "Company", label: "Company" },
                { value: "Human Resources", label: "Human Resources" },
                { value: "Financial", label: "Financial" },
                { value: "Meeting", label: "Meeting" },
                { value: "Employee Activities", label: "Employee Activities" },
                { value: "Project or New Initiative", label: "Project or New Initiative" },
                { value: "Internal Communication", label: "Internal Communication" },
                { value: "Safety", label: "Safety" },
                { value: "Training and Development", label: "Training and Development" },
                { value: "Health and Welfare", label: "Health and Welfare" },
            ],
            devOptions: [
                { value: 'DIR', label: 'DIR' },
                { value: 'MNG', label: 'MNG' },
                { value: 'DR', label: 'DR' },
                { value: 'ADMIN', label: 'ADMIN' },
                { value: 'ASST', label: 'ASST' },
                { value: 'S-FIN', label: 'S-FIN' },
                { value: 'FIN', label: 'FIN' },
                { value: 'DEV', label: 'DEV' },
                { value: 'PUR', label: 'PUR' },
                { value: 'AR', label: 'AR' },
                { label: 'HIB', value: 'HIB' },
                { label: 'IB', value: 'IB' },
                { label: 'HRN', value: 'HRN' },
                { value: 'RN', label: 'RN' },
                { value: 'DRN', label: 'DRN' },
                { value: 'PNS', label: 'PNS' },
                { label: 'PMD', value: 'PMD' },
                { label: 'HPH', value: 'HPH' },
                { label: 'DPH', value: 'DPH' },
                { label: 'PH', value: 'PH' },
                { label: 'MT', value: 'MT' },
                { label: 'WA', value: 'WA' },
                { label: 'DV', value: 'DV' },
                { label: 'HK', value: 'HK' },
                { label: 'KT', value: 'KT' },
                { label: 'BF', value: 'BF' },
                { label: 'HOP', value: 'HOP' },
                { value: 'All', label: 'All' }

            ],
            selectedDepartment: 'All',
            unsubscribe: null 
        };
    },
    computed: {
        isFilterActive() {
            return this.Filter.Topic || this.Filter.Prioritization || this.Filter.Day || this.Filter.Department.length > 0;
        },
        // user() {
        //     return this.$store.getters.user;  // เข้าถึงข้อมูลผู้ใช้จาก Vuex
        // }
    },
    watch: {
    },
    mounted() {
        this.fetchNews()
        // this.fetchTopNews()
        // this.fetchOther();
    },
    beforeDestroy() {
        // ยกเลิกการสมัคร listener เมื่อ component ถูกทำลาย
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    },
    methods: {
        fetchNews() {
        this.unsubscribe = db.collection("Ft_announcement")
            .orderBy('createdAt', 'desc')
            .onSnapshot(querySnapshot => {
                this.News = [];
                querySnapshot.forEach((doc) => {
                    let newsData = doc.data();

                    // แปลง createdAt เป็น "YYYY-MM-DD HH:mm"
                    if (newsData.createdAt && typeof newsData.createdAt.toDate === "function") {
                        newsData.createdAt = moment(newsData.createdAt.toDate()).format("YYYY-MM-DD HH:mm");
                    }

                    // แปลง Showing เป็น "YYYY-MM-DD"
                    if (newsData.Showing && typeof newsData.Showing.toDate === "function") {
                        newsData.Showing = moment(newsData.Showing.toDate()).format("YYYY-MM-DD");
                    }

                    // แปลง updatedAt เป็น "YYYY-MM-DD HH:mm"
                    if (newsData.updatedAt && typeof newsData.updatedAt.toDate === "function") {
                        newsData.updatedAt = moment(newsData.updatedAt.toDate()).format("YYYY-MM-DD HH:mm");
                    }

                    // เก็บข้อมูลที่แปลงแล้วใน this.News
                    this.News.push({
                        ...newsData
                    });
                });
               
                this.fetchTopNews();
                this.fetchOther();
            
            }, error => {
                console.log("Error getting documents: ", error);
            });
    },
        processNews(category, selectedItemsCount) {
            let normalItems = [];
            let finalItems = [];

            let mainItems = this.News.filter(item => item.Category === category);

            // ขั้นตอนที่ 2: ตรวจสอบว่า Showing Date <= วันที่ปัจจุบัน
            for (let index = 0; index < mainItems.length; index++) {
                const showingDate = new Date(mainItems[index].Showing);
                const currentDate = new Date(); // Current date

                if (showingDate <= currentDate) {
                    const formattedDate = showingDate.toISOString().split('T')[0]; // แปลงวันที่เป็น "YYYY-MM-DD"
                    mainItems[index].formattedDate = formattedDate;
                    normalItems.push(mainItems[index]); // เก็บไว้ใน normalItems
                }
            }

            // ขั้นตอนที่ 3: เลือกข่าวที่มี Hold ไปเก็บไว้ใน DataTop
            let holdItems = normalItems.filter(item => item.Hold === true);
            finalItems = [...holdItems]; // เก็บ Hold ไว้ใน DataTop

            // ขั้นตอนที่ 4: เลือก 5 ชุดจากที่เหลือ โดยจัดลำดับตาม Level (High -> Low) และเลือกจากเวลาล่าสุด
            // let levelItems = normalItems.filter(item => item.Hold !== true); // ลบออกจาก Hold

            // // เรียงจาก Level High -> Low
            // levelItems.sort((a, b) => {
            //     // เรียงตาม Level: High มาก่อน
            //     if (a.Level === "High" && b.Level !== "High") return -1;
            //     if (a.Level !== "High" && b.Level === "High") return 1;

            //     // ถ้า Level เท่ากัน ให้เรียงตามเวลาล่าสุด
            //     return new Date(b.createdAt) - new Date(a.createdAt); // ใช้เวลาล่าสุดในการเรียง
            // });

            // เลือก 5 ชุดจากที่เหลือ
            let topLevelItems = [];

            // ตัวอย่าง: เลือก 5 รายการถ้าไม่มีเงื่อนไขพิเศษ
            if (selectedItemsCount) {
                topLevelItems = normalItems.slice(0, selectedItemsCount);  // ใช้ค่า count ที่เลือกจาก UI หรือเงื่อนไขอื่น ๆ
            } else {
                // หรือเลือกทุกชุดที่เหลือทั้งหมด
                topLevelItems = normalItems;  // เลือกทุกชุด
            }

            // รวม normalItems, holdItems, และ topLevelItems
            return [...finalItems, ...topLevelItems]; // คืนค่าผลลัพธ์ที่รวมทั้งหมด
        },
        setectOther(category) {
            let normalItems = [];
            // // กรองตาม Category
            let mainItems = this.News.filter(item => item.Category === category);

            // ขั้นตอนที่ 2: ตรวจสอบว่า Showing Date <= วันที่ปัจจุบัน
            for (let index = 0; index < mainItems.length; index++) {
                const showingDate = new Date(mainItems[index].Showing);
                const currentDate = new Date(); // Current date

                if (showingDate <= currentDate) {
                    const formattedDate = showingDate.toISOString().split('T')[0]; // แปลงวันที่เป็น "YYYY-MM-DD"
                    mainItems[index].formattedDate = formattedDate;
                    normalItems.push(mainItems[index]); // เก็บไว้ใน normalItems
                }
            }

            let DateItems = normalItems.filter(item => {
                // ตรวจสอบว่า Department ตรงกับค่าที่เลือกใน Filter หรือไม่
                const departmentMatch = this.Filter.Department.length > 0
                    ? this.Filter.Department.some(dep =>
                        item.Department.some(dept => dept.value === dep)  // เปรียบเทียบ value ของ dept
                    )
                    : true;  // ถ้า Filter.Department ว่าง (ไม่มีการกรอง), ให้ตรงทุกข่าว

                // ตรวจสอบว่า Topic ตรงกับค่าที่เลือกใน Filter หรือไม่
                const topicMatch = this.Filter.Topic ? item.Topic === this.Filter.Topic : true;

                // ตรวจสอบว่า Prioritization ตรงกับค่าที่เลือกใน Filter หรือไม่
                const prioritizationMatch = this.Filter.Prioritization ? item.Prioritization === this.Filter.Prioritization : true;

                // ตรวจสอบว่าทั้ง Department, Topic, และ Prioritization ตรงกันทั้งหมด
                return departmentMatch && topicMatch && prioritizationMatch;
            });

            return DateItems; // คืนผลลัพธ์ที่กรองแล้วและไม่ซ้ำ
        },
        fetchTopNews() {
            if (this.selectedDepartment === "All") {
                this.DataTop = [];
                this.DataTop = this.processNews("Top News", 4);
            }
            else {
                this.DataTop = [];
                this.DataTop = this.processNews("Top News", 4)
            }
        },
        fetchOther() {
            if (this.selectedDepartment === "All") {
                this.Other = [];
                this.Other = this.processNews("Other News");
            }
            else if (this.selectedDepartment === "Select") {
                this.Other = [];
                this.Other = this.setectOther("Other News")
            }
        },
        // fetchBookMark() {
        //     console.log(this.News);
        //     console.log(this.$store.getters.user.displayName);
        //     this.BookMark = [];

        //     this.BookMark = this.News.filter(item => {
        //         // ตรวจสอบว่า item.Bookmark มีค่าเป็นอาร์เรย์และไม่เป็น undefined หรือ null
        //         return Array.isArray(item.Bookmark) && item.Bookmark.includes(this.$store.getters.user.displayName);
        //     });
        // },

        handleFilter() {
            this.selectedDepartment = "Select";
            this.fetchOther();
        },
        handleClear() {
            this.Filter = {
                Topic: null,
                Prioritization: null,
                Day: '',
                Department: []
            };
            this.selectedDepartment = "All";
            this.fetchNews();
        },

    }
};
</script>

<style lang="scss" scoped>
.Announcement {
    background-color: rgb(255, 255, 255);
    // width: 75%;
     width: 60%;
    height: 100vh;

    // .Top-News {
    //     background-color: transparent !important;
    //     // height: 560px;
    //     height: auto;
    //     margin: 20px 0;
    //     padding: 10px;
    //     border-radius: 8px;
    //     width: 80%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     border: 2px solid #eeeded;
    //     /* กำหนดสีกรอบ */
    // }

    // .Other-News {
    //     background-color: transparent !important;
    //     // height: 500px;
    //     border-radius: 8px;
    //     width: 100%;
    // }
}
</style>
