<template>
    <div class="row p-2">

        <div class="col-12">
            <date-filter v-model="filter" @input="triggerInitData" :search-date-type.sync="filter.search_date_type" />
        </div>
        <div class="col-6 mt-2">
            <table class="table table-bordered">
                <thead>
                    <th></th>
                    <th>HOSPITAL</th>
                    <th>Slinky Refferral</th>
                    <th>Aesthetic</th>
                    <th>Pier Referral</th>
                    <th>Central Referral</th>
                    <th>TOTAL</th>
                </thead>
                <tr v-for="(label, index) in chartData.labels" :key="label">
                    <td>{{ label }}</td>
                    <td>{{ chartData.datasets[0].data[index] }}</td>
                    <td>{{ chartData.datasets[1].data[index] }}</td>
                    <td>{{ chartData.datasets[2].data[index] }}</td>
                    <td>{{ chartData.datasets[3].data[index] }}</td>
                    <td>{{ chartData.datasets[4].data[index] }}</td>
                    <td>{{ chartData.datasets[0].data[index] + chartData.datasets[1].data[index] +
                        chartData.datasets[2].data[index] + chartData.datasets[3].data[index] +
                        chartData.datasets[4].data[index] }}
                    </td>
                </tr>
                <tr>
                    <td>SUM</td>
                    <td>{{chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
                    <td>{{chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
                    <td>{{chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
                    <td>{{chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
                    <td>{{chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
                    <td>{{chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) +
                        chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) +
                        chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0) +
                        chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0) +
                        chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
                </tr>
                <tr>
                    <td>AVG</td>
                    <td>{{(chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) /
                        sum_days).toFixed(2)}}</td>
                    <td>{{(chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) /
                        sum_days).toFixed(2)}}</td>
                    <td>{{(chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0) /
                        sum_days).toFixed(2)}}</td>
                    <td>{{(chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0) /
                        sum_days).toFixed(2)}}</td>
                    <td>{{(chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0) /
                        sum_days).toFixed(2)}}</td>
                    <td>{{((chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) +
                        chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) +
                        chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0) +
                        chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0) +
                        chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0)) / sum_days).toFixed(2)
                    }}</td>
                </tr>
            </table>
        </div>
        <div class="col-6 mt-2">
            <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
                :chart-id="'patients_number_chart'" :width="400" :height="400" />
        </div>
    </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import Chart from 'chart.js/auto';
import { db } from '../../db'
import moment from "moment";
export default {
    //props: ['patients','visits'],
    components: {
        LineChartGenerator
    },

    data() {
        return {
            // from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
            // to: this.$moment().format('DD/MM/YYYY'),
            sum_days: 30,
            chartOptions: {
                responsive: true,
                plugins: {
                    datalabels: {
                        color: 'black',
                        font: {
                            weight: 'bold',
                            size: 12,
                        },
                        anchor: 'end', // attaches label to the top of the point
                        align: 'top',  // positions it above the point
                        offset: 4      // optional: spacing from the point
                    }
                },
            },
            filteredPatients: [],
            filter: {
                from: this.$moment().startOf('month').format('DD/MM/YYYY'),
                to: this.$moment().format('DD/MM/YYYY'),
                search_date_type: 'by-range'
            },
        }
    },
    mounted() {
        this.loadData()
    },
    computed: {
        chartData() {

            let filteredPatients = this.filteredPatients

            let fromMoment = this.$moment(this.filter.from, 'DD/MM/YYYY')

            // generate months labels
            //label
            let labels = []
            let startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')

            for (let i = 0; i < 24; i++) {
                labels.push(startLabelMoment.format('HH:mm'))
                startLabelMoment.add(1, 'hour')
            }

            //data
            startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')
            let hospitalData = []
            let slinkyData = []
            let aestheticData = []
            let pierData = []
            let centralData = []
            let totalData = []

            for (let i = 0; i < 24; i++) {
                hospitalData.push(0)
                slinkyData.push(0)
                aestheticData.push(0)
                pierData.push(0)
                centralData.push(0)
                totalData.push(0)
            }

            for (let p of filteredPatients) {

                if (p.visit_date) {
                    let hour = this.$moment(p.visit_date.toDate()).hour()
                    if (p.branch && p.branch.id === '1') hospitalData[hour]++
                    if (p.branch && p.branch.id === '2') slinkyData[hour]++
                    if (p.branch && p.branch.id === '3') aestheticData[hour]++
                    if (p.branch && p.branch.id === '4') pierData[hour]++
                    if (p.branch && p.branch.id === '5') centralData[hour]++
                    totalData[hour]++
                }
            }

            return {
                labels,
                datasets: [
                    {
                        label: 'HOSPITAL',
                        data: hospitalData,
                        backgroundColor: [
                            'rgb(25, 118, 210)',
                        ],
                        borderColor: [
                            'rgb(25, 118, 210)',

                        ],
                        borderWidth: 1,
                        suggestedMin: -10,
                        suggestedMax: 200,
                        cubicInterpolationMode: 'monotone'
                    },
                    {
                        label: 'SLINKY REFERRAL',
                        data: slinkyData,
                        backgroundColor: [
                            'rgb(211, 47, 47)',
                        ],
                        borderColor: [
                            'rgb(211, 47, 47)',
                        ],
                        borderWidth: 1,
                        cubicInterpolationMode: 'monotone'
                    },
                    {
                        label: 'AESTHETIC',
                        data: aestheticData,
                        backgroundColor: [
                            'rgb(251, 192, 45)',
                        ],
                        borderColor: [
                            'rgb(251, 192, 45)',
                        ],
                        borderWidth: 1,
                        cubicInterpolationMode: 'monotone'
                    },
                    {
                        label: 'PIER REFERRAL',
                        data: pierData,
                        backgroundColor: [
                            'rgb(56, 142, 60)',
                        ],
                        borderColor: [
                            'rgb(56, 142, 60)',
                        ],
                        borderWidth: 1,
                        cubicInterpolationMode: 'monotone'
                    },
                    {
                        label: 'CENTRAL REFERRAL',
                        data: centralData,
                        backgroundColor: [
                            'rgb(123, 31, 162)',
                        ],
                        borderColor: [
                            'rgb(123, 31, 162)',
                        ],
                        borderWidth: 1,
                        cubicInterpolationMode: 'monotone'
                    },
                    // {
                    //   label: 'Total',
                    //   data: totalData,
                    //   borderWidth: 1,
                    //   cubicInterpolationMode: 'monotone'
                    // },

                ]
            }
        }
    },
    watch: {
        from: 'calculateSumDays',
        to: 'calculateSumDays'
    },
    methods: {
        calculateSumDays() {
            let fromMoment = this.$moment(this.filter.from, 'DD/MM/YYYY');
            let toMoment = this.$moment(this.filter.to, 'DD/MM/YYYY');
            this.sum_days = toMoment.diff(fromMoment, 'days');

        },
        async loadData() {
            const loader = this.$loading.show({})
            this.filteredPatients = []
            try {

                let start = moment(this.filter.from, "DD/MM/YYYY").startOf('day').toDate()
                let end = moment(this.filter.to, "DD/MM/YYYY").endOf('day').toDate()

                let patientRef = await db.collection('Visit')
                    .where("visit_date", ">=", start)
                    .where("visit_date", "<", end)
                    .get()
                this.filteredPatients = patientRef.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                })



            }
            catch (error) {
                console.error("Error getting documents: ", error);
            }
            finally {
                loader.hide()
            }
        },
        triggerInitData() {

            this.loadData()

        },

    }
}
</script>