<template>
  <div>
    <div class="row p-2">

      <div class="col-12">
        <date-filter v-model="filter" @input="triggerInitData" :search-date-type.sync="filter.search_date_type" />
      </div>
    </div>

    <!-- <h3 class="text-center">Tourist Range of stay chart</h3> -->
    <div class="row p-2">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th># of Patients</th>
          </thead>
          <tr v-for="(label, index) in chartData.labels" :key="label">
            <td>{{ label }}</td>
            <td>{{ chartData.datasets[0].data[index] }}</td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Pie :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" />
      </div>
    </div>


  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import color from '../../assets/color.json'
import Chart from 'chart.js/auto';
import { db } from '../../db'
import moment from "moment";

const length_of_stay = [
  {
    label: 'less than 1 day',
    min: 0,
    max: 1
  },
  {
    label: '1-2 Nights',
    min: 1,
    max: 2
  },
  {
    label: '3 Nights  -  1 Week',
    min: 2,
    max: 7
  },
  {
    label: '1 Week - 1 Month',
    min: 7,
    max: 30
  },
  {
    label: 'More than 1 Month',
    min: 7,
    max: 999
  },
]

export default {
  //props: ['patients'],
  components: {
    Pie
  },
  data() {
    return {
      // from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      // to: this.$moment().format('DD/MM/YYYY'),
      chartOptions: {
        responsive: true,
        plugins: {
          datalabels: {
            color: 'black',
            font: {
              weight: 'bold',
              size: 12,
            },
            formatter: (value, context) => {
              const dataset = context.chart.data.datasets[0]
              const total = dataset.data.reduce((sum, val) => sum + val, 0)

              if (total === 0) return '0%'

              const percent = ((value / total) * 100).toFixed(1)
              return `${value} (${percent}%)`
            }
          }
        }
      },
      filteredPatients: [],
      filter: {
        from: this.$moment().startOf('month').format('DD/MM/YYYY'),
        to: this.$moment().format('DD/MM/YYYY'),
        search_date_type: 'by-range'
      },
    }
  },
  mounted() {
    this.loadData()
  },
  computed: {
    chartData() {

      let filteredPatients = this.filteredPatients

      //label
      let labels = length_of_stay.map(l => l.label)
      let data = [0, 0, 0, 0, 0]

      for (let p of filteredPatients) {
        if (p.arrival_date && p.departure_date) {
          let days_stay = this.$moment(p.departure_date.toDate()).diff(this.$moment(p.arrival_date.toDate()), 'day')

          length_of_stay.forEach((item, index) => {
            if (days_stay >= item.min && days_stay < item.max) data[index]++
          })
        }
      }

      let sortedData = []
      for (let i = 0; i < data.length; i++) {
        sortedData.push({
          label: labels[i],
          value: data[i]
        })
      }

      return {
        labels: this._.orderBy(sortedData, 'value', 'desc').map(obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: this._.orderBy(sortedData, 'value', 'desc').map(obj => obj.value)
          }
        ]
      }
    }
  },
  methods: {
    async loadData() {
      const loader = this.$loading.show({})
      this.filteredPatients = []
      try {

        let start = moment(this.filter.from, "DD/MM/YYYY").startOf('day').toDate()
        let end = moment(this.filter.to, "DD/MM/YYYY").endOf('day').toDate()

        let patientRef = await db.collection('Patient')
          .where("created_at", ">=", start)
          .where("created_at", "<", end)
          .get()
        this.filteredPatients = patientRef.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          }
        })



      }
      catch (error) {
        console.error("Error getting documents: ", error);
      }
      finally {
        loader.hide()
      }
    },
    triggerInitData() {

      this.loadData()

    },

  }
}
</script>