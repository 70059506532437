<template>
  <div  class="Announcement-Top-News">
    <div class="row">
      <h2 class="top-stories-header">Top Stories</h2>
    </div>
    <div class="row top-stories" style="margin-top: 18px;">
      <div class="col-12 col-md-6 head" v-if="newsTop.length > 0">
        <div class="card-news">
          <div class="news-image-container">
          <img :src="newsTop[0]?.Images || require('./images/NoImg.svg')" alt="News Image" class="news-image"
            @click="openModal(newsTop[0])" />
          </div>
          <div class="Title-Image">
            <div v-if="newsTop[0].Prioritization == 'IMPORTANT'">
                  <i class="fa fa-star" style="color: #ffa500ff; margin-right: 5px;  font-size: 25px;" aria-hidden="true"></i>
              </div>
              <div v-if="newsTop[0].Prioritization == 'GENERAL'">
                <i class="fa fa-graduation-cap"  style="color: #3f51b5; margin-right: 5px; font-size: 25px;" aria-hidden="true"></i>
              </div>
              <div v-if="newsTop[0].Prioritization == 'CRITICAL'">
                <i class="fa fa-exclamation-triangle"  style="color: #dc3545ff; margin-right: 5px; font-size: 25px;" aria-hidden="true"></i>
              </div>
            <div class="Topic" :style="{
              color: getColor(newsTop[0].Prioritization)
            }" @click="openModal(newsTop[0])">
              {{ newsTop[0]?.Topic }}
            </div>
            <!-- Bookmarked for newsTop[0] -->
            <button @click="toggleBookmark(newsTop[0].id, newsTop[0])" style="background: none; border: none;"
              class="ml-auto">
              <span
                v-if="newsTop[0].Bookmark && Array.isArray(newsTop[0].Bookmark) && newsTop[0].Bookmark.includes(user.displayName)"
                class="bookmarked">
                <i class="fa fa-bookmark" aria-hidden="true"></i>
              </span>
              <span v-else class="not-bookmarked">
                <i class="fa fa-bookmark" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="Title" v-html="addIndentAndTruncate(newsTop[0]?.Title, 60)" @click="openModal(newsTop[0])"></div>
          <div v-if="newsTop[0]?.Department.length > 0">
            <div class="Department">
              <button v-for="(dept, index) in newsTop[0]?.Department.slice(0, 10)" :key="index" class="btn btn-sm mx-1"
                :style="{
                  backgroundColor: getColorDepartment(dept.value),
                  color: dept.value === 'WA' || dept.value === 'MT' ? '#000000' : '#fff' + ' !important',
                  fontSize: '8px',
                  fontWeight: 'bold',
                  padding: '5px 5px',
                }">
                {{ dept.label }}
              </button>
              <button v-if="newsTop[0]?.Department.length > 10" :id="'tooltip-card-' + newsTop[0]?.id"
                class="button-tooltip">
                More
              </button>
              <b-popover v-if="newsTop[0]?.Department.length > 10" :target="'tooltip-card-' + newsTop[0]?.id"
                triggers="hover" style="background-color: black !important;">
                <div class="tooltip-department">
                  <button v-for="(dept, index) in newsTop[0]?.Department.slice(10)" :key="index" class="btn btn-sm mx-1"
                    :style="{
                      backgroundColor: getColorDepartment(dept.value),
                      color: dept.value === 'WA' || dept.value === 'MT' ? '#000000' : '#fff' + ' !important',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      padding: '5px 5px',
                      marginBottom: '2px'
                    }">
                    {{ dept.label }}
                  </button>
                </div>
              </b-popover>
            </div>
          </div>
          <div class="footer">
            <div class="Footer-From">
              <small class="time-footer" style="margin-right: 10px;">
                <small>{{ timeSince(newsTop[0]?.updatedAt) }}</small>
              </small>
              <template v-if="newsTop[0]?.Avatar">
                <img :src="newsTop[0].Avatar" alt="Author Avatar" class="footer-avatar" />
              </template>
              <template v-else>
                <div class="footer-avatar-placeholder">
                  {{ newsTop[0]?.Author?.charAt(0).toUpperCase() || "?" }}
                </div>
              </template>
              <span class="footer-author-name">{{ newsTop[0]?.Author || "Unknown" }}</span>

              <div style="display: flex; align-items: center; margin-left: 10px;">
                <i class="likes-count fa fa-user" aria-hidden="true" style="font-size: 12px;"> </i>
                <p>{{ newsTop[0]?.views || 0 }}</p>
              </div>
            </div>
            <div>
              <b-button v-b-toggle="`collapse-head-News`" variant="primary" class="mx-1 footer-btn">
                <i class="fa fa-comments" aria-hidden="true"></i>
              </b-button>

            </div>
          </div>
        </div>
        <b-collapse :id="`collapse-head-News`" class="mt-3" style="overflow-x: auto;">
          <b-card style="height: auto; width: 100%;  border: none; margin-top: -15px;">
            <div v-for="(comment, index) in newsTop[0]?.Comments" :key="index" class="comment-section">
              <div class="row">
                <div class="col-1">
                  <img :src="comment.userAvatar" class="comment-avatar" alt="User Avatar" />
                </div>
                <div class="col-10">
                  <div class="comment-header d-flex align-items-center">
                    <div class="ml-1">
                      <span class="user-name">{{ comment.userName }}</span>
                      <span class="comment-time ml-3">{{ timestampSince(comment.createdAt) }}</span>
                    </div>
                  </div>
                  <p class="comment-text">{{ comment.text }}</p>
                </div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
      <!-- รายการข่าวอื่น -->
      <div class="col-12 col-md-6 Another" v-if="paginatedItems.length > 0">
        <ul class="news-list">
          <li v-for="(card, index) in paginatedItems" :key="index" class="news-item">
            <div class="row">
              <div class="col-8 col-md-8 col">
                <div class="Title-Image">
                  <div v-if="card.Prioritization == 'IMPORTANT'">
                  <i class="fa fa-star" style="color: #ffa500ff; margin-right: 5px;  font-size: 25px;" aria-hidden="true"></i>
              </div>
              <div v-if="card.Prioritization == 'GENERAL'">
                <i class="fa fa-graduation-cap"  style="color: #3f51b5; margin-right: 5px; font-size: 25px;" aria-hidden="true"></i>
              </div>
              <div v-if="card.Prioritization == 'CRITICAL'">
                <i class="fa fa-exclamation-triangle"  style="color: #dc3545ff; margin-right: 5px; font-size: 25px;" aria-hidden="true"></i>
              </div>
                  <div class="Topic" :style="{
                    color: getColor(card.Prioritization)
                  }" @click="openModal(card)">
                    {{ card.Topic }}
                  </div>
                  <!-- Bookmarked for each card -->
                  <button @click="toggleBookmark(card.id, card)" style="background: none; border: none;"
                    class="ml-auto">
                    <span
                      v-if="card.Bookmark && Array.isArray(card.Bookmark) && card.Bookmark.includes(user.displayName)"
                      class="bookmarked">
                      <i class="fa fa-bookmark" aria-hidden="true"></i>
                    </span>
                    <span v-else class="not-bookmarked">
                      <i class="fa fa-bookmark" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div class="Title" v-html="addIndentAndTruncate(card.Title, 65)" @click="openModal(card)"></div>
                <div class="footer">
                  <div class="Footer-From">
                    <small class="time-footer" style="margin-right: 10px;">
                      <small>{{ timeSince(card.updatedAt) }}</small>
                    </small>
                    <template v-if="card.Avatar">
                      <img :src="card.Avatar" alt="Author Avatar" class="footer-avatar" />
                    </template>
                    <template v-else>
                      <div class="footer-avatar-placeholder">
                        {{ typeof card.Author === 'string' && card.Author.length > 0
                          ? card.Author.charAt(0).toUpperCase()
                          : "?" }}
                      </div>
                    </template>
                    <span class="footer-author-name">{{ card.Author || "Unknown" }}</span>
                    <div v-if="card.Department.length > 0">
                      <div class="Department">
                        <button v-for="(dept, index) in card.Department.slice(0, 2)" :key="index"
                          class="btn btn-sm mx-1" :style="{
                            backgroundColor: getColorDepartment(dept.value),
                            color: dept.value === 'WA' || dept.value === 'MT' ? '#000000' : '#fff' + ' !important',
                            fontSize: '8px',
                            fontWeight: 'bold',
                            padding: '5px 5px',
                          }">
                          {{ dept.label }}
                        </button>
                        <button v-if="card.Department.length > 2" :id="'tooltip-card-' + card.id"
                          class="button-tooltip">
                          More
                        </button>

                        <b-popover v-if="card.Department.length > 2" :target="'tooltip-card-' + card.id"
                          triggers="hover" style="background-color: black !important;">
                          <div class="tooltip-department">
                            <button v-for="(dept, index) in card.Department.slice(2)" :key="index"
                              class="btn btn-sm mx-1" :style="{
                                backgroundColor: getColorDepartment(dept.value),
                                color: dept.value === 'WA' || dept.value === 'MT' ? '#000000' : '#fff' + ' !important',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                padding: '5px 5px',
                                marginBottom: '2px'
                              }">
                              {{ dept.label }}
                            </button>
                          </div>
                        </b-popover>
                      </div>
                    </div>
                    <div style="display: flex; align-items: center; margin-left: 10px;">
                      <i class="likes-count fa fa-user" aria-hidden="true" style="font-size: 12px;"> </i>
                      <p>{{ card?.views || 0 }}</p>
                    </div>
                    <div>

                      <b-button v-b-toggle="`collapse-Another-${index}`" variant="primary" class="mx-1 footer-btn">
                        <i class="fa fa-comments" aria-hidden="true"></i>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="news-image-container">
                  <img :src="card.Images || require('./images/NoImg.svg')" 
                      alt="News Image" 
                      class="news-image"
                      @click="openModal(card)" />
                </div>
              </div>
            </div>
            <b-collapse :id="`collapse-Another-${index}`" class="mt-3" style="overflow-x: auto;">

              <b-card style="height: auto; width: 100%;  border: none; margin-top: -15px;">
                <div v-for="(comment, index) in card.Comments" :key="index" class="comment-section">
                  <div class="row">
                    <div class="col-1">
                      <img :src="comment.userAvatar" class="comment-avatar" alt="User Avatar" />
                    </div>
                    <div class="col-10">
                      <div class="comment-header d-flex align-items-center">
                        <div class="ml-1">
                          <span class="user-name">{{ comment.userName }}</span>
                          <span class="comment-time ml-3">{{ timestampSince(comment.createdAt) }}</span>
                        </div>
                      </div>
                      <p class="comment-text">{{ comment.text }}</p>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-collapse>

          </li>
        </ul>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="showModal" class="modal" @click.self="closeModal">
      <ModelContent :modalContent="modalContent" @closeModal="closeModal" />
    </div>
  </div>
</template>

<script>
import ModelContent from './modal.vue'
import firebase from 'firebase/app';
import { db, storage } from '../../../db'
import moment from 'moment'
export default {
  components: {
    ModelContent,
  },
  props: {
    newsTop: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      modalContent: {},
      newCommentText: "",
    };
  },
  computed: {
    paginatedItems() {
      return this.newsTop.slice(1, 4); // แสดงข่าวจาก array ที่ 2, 3, 4 (index 1, 2, 3)
    },

    user() {
      return this.$store.getters.user;  // เข้าถึงข้อมูลผู้ใช้จาก Vuex
    }
  },
  mounted() {
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.trackAnnouncementView()
      }
    }
  },
  methods: {
    async toggleBookmark(articleId, card) {
      try {
        const userId = this.user.displayName; // fix: ใช้ this.user จาก computed
        const index = this.newsTop.findIndex(item => item.id === articleId); // fix: ใช้ newsTop แทน item

        if (index === -1) return;

        const item = this.newsTop[index]; // fix: ใช้ newsTop
        const docRef = db.collection('Ft_announcement').doc(articleId);
        const docSnapshot = await docRef.get();

        if (!docSnapshot.exists) {
          await docRef.set({ Bookmark: [userId] });
          this.$set(this.newsTop[index], 'Bookmark', [userId]);
          if (card) this.$set(card, 'Bookmark', [userId]);
        } else {
          const currentBookmarks = docSnapshot.data().Bookmark || [];

          if (currentBookmarks.includes(userId)) {
            await docRef.update({
              Bookmark: firebase.firestore.FieldValue.arrayRemove(userId),
            });
            const updated = currentBookmarks.filter(uid => uid !== userId);
            this.$set(this.newsTop[index], 'Bookmark', updated);
            if (card) this.$set(card, 'Bookmark', updated);
          } else {
            await docRef.update({
              Bookmark: firebase.firestore.FieldValue.arrayUnion(userId),
            });
            const updated = [...currentBookmarks, userId];
            this.$set(this.newsTop[index], 'Bookmark', updated);
            if (card) this.$set(card, 'Bookmark', updated);
          }
        }
      } catch (error) {
        console.error("Error toggling bookmark:", error);
      }
    }

    ,
    // ฟังก์ชันการเพิ่ม userId ลงใน Bookmark
    async addBookmarkToAnnouncement(articleId, userId) {
      try {
        const docRef = db.collection('Ft_announcement').doc(articleId);
        const docSnapshot = await docRef.get();

        // ถ้าเอกสารไม่พบ ให้สร้างใหม่
        if (!docSnapshot.exists) {
          await docRef.set({
            Bookmark: [userId], // ถ้าไม่มีฟิลด์ Bookmark ให้สร้างใหม่และเพิ่ม userId
          });
        } else {
          // ถ้าเอกสารมีอยู่แล้ว ให้เพิ่ม userId ลงใน array
          await docRef.update({
            Bookmark: firebase.firestore.FieldValue.arrayUnion(userId), // เพิ่ม userId เข้าไปใน array
          });
        }
      } catch (error) {
        console.error("Error adding bookmark to Ft_announcement:", error);
      }
    },

    // ฟังก์ชันการลบ userId จาก Bookmark
    async removeBookmarkFromAnnouncement(articleId, userId) {
      try {
        const docRef = db.collection('Ft_announcement').doc(articleId);
        await docRef.update({
          Bookmark: firebase.firestore.FieldValue.arrayRemove(userId), // ลบ userId ออกจาก array
        });
      } catch (error) {
        console.error("Error removing bookmark from Ft_announcement:", error);
      }
    }
    ,
    async trackAnnouncementView() {
      try {
        const displayName = this.$store.getters.user.displayName
        const docId = this.modalContent?.id
        if (!docId || !displayName) return

        const docRef = firebase.firestore().collection('Ft_announcement').doc(docId)
        const docSnap = await docRef.get()

        if (docSnap.exists) {
          const data = docSnap.data()
          const readers = data.readers || []
          const alreadyRead = readers.some(r => r.name === displayName)

          if (!alreadyRead) {
            const newReader = {
              name: displayName,
              timestamp: moment(new Date()).format("YYYYMMDDTHHmm")
            }

            await docRef.update({
              readers: firebase.firestore.FieldValue.arrayUnion(newReader),
              views: firebase.firestore.FieldValue.increment(1)
            })
          }
        }
      } catch (err) {
        console.error("Error tracking view:", err)
      }
    },
    getPrioritizationImage(prioritization) {
      try {
        return require(`./images/${prioritization}.svg`);
      } catch (e) {
        return require('./images/NoImg.svg'); // fallback ถ้าไม่เจอ
      }
    },

    truncateHtml(content, maxLength) {
      let div = document.createElement("div");
      div.innerHTML = content;
      let text = div.innerText || div.textContent;
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    addIndentAndTruncate(content, maxLength) {
      const indent = '&nbsp;'.repeat(5); // ย่อหน้า 5 ช่อง
      const truncated = this.truncateHtml(content, maxLength);
      return indent + truncated;
    },

    openModal(item) {
      this.modalContent = item;
      this.showModal = true;
      document.body.style.overflow = 'hidden';
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = 'auto';
    },
    getColor(value) {
      const importanceLevels = {
        'CRITICAL': '#dc3545',
        'IMPORTANT': '#FFA500',
        'GENERAL': '#A0A0A0', //
        'REFERENCE': '#none',
      };
      // ตรวจสอบว่า value มีใน importanceLevels หรือไม่
      return importanceLevels[value] || '#cccccc'; // ถ้าไม่มีให้ใช้สีเทาเป็นค่า default
    },

    getColorDepartment(value) {
      const colors = {
        'DIR': '#2F6EBA', //*
        'MNG': '#4D96FF',//*
        'DR': '#274E13',//
        'ADMIN': '#4D96FF',//*
        'ASST': '#4D96FF',//*
        // 'S-FIN': '#FFB74D',//
        'FIN': '#FFB74D',//
        'DEV': '#FFA500',//*
        'PUR': '#FFA500',//*
        'AR': '#E64A19',//
        // 'HIB': '#4FC3F7',//3C78D8
        'IB': '#4FC3F7',//
        // 'HRN': '#388E3C',//
        'RN': '#388E3C',//
        // 'DRN': '#388E3C',//
        'PMD': '#388E3C',//
        "PNS": "#4FC3F7",// Partnership
        // 'HPH': '#8BC34A',//
        // 'DPH': '#8BC34A',//
        'PH': '#8BC34A',//
        'MT': '#C5E1A5',//* Colors.lightBlue.shade200 
        'WA': '#FFECB3',//
        'DV': '#B71C1C',///
        'HK': '#F9A825',///
        'KT': '#795548',//* Colors.brown
        "BF": "#FFCA28",//
        "HOP": "#01579B",//
        'All': '#F44336',///
      };
      return colors[value] || '#cccccc'; // Default Grey
    },

    timestampSince(dateStr) {
      // แปลง '20250409T1702' => '2025-04-09T17:02:00'
      const formatted = dateStr.replace(
        /^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})$/,
        '$1-$2-$3T$4:$5:00'
      );
      const past = new Date(formatted);
      const now = new Date();
      const seconds = Math.floor((now - past) / 1000);
      let interval = seconds / 31536000;
      if (interval >= 1) return Math.floor(interval) + " ปีที่แล้ว";
      interval = seconds / 2592000;
      if (interval >= 1) return Math.floor(interval) + " เดือนที่แล้ว";
      interval = seconds / 86400;
      if (interval >= 1) return Math.floor(interval) + " วันที่แล้ว";
      interval = seconds / 3600;
      if (interval >= 1) return Math.floor(interval) + " ชั่วโมงที่แล้ว";
      interval = seconds / 60;
      if (interval >= 1) return Math.floor(interval) + " นาทีที่แล้ว";
      return "ไม่กี่วินาทีที่แล้ว";
    }
    ,
    timeSince(dateStr) {
      // แปลงวันที่ให้เป็นรูปแบบที่ Date() อ่านได้
      const past = new Date(dateStr.replace(/-/g, '/'));
      const now = new Date();
      const seconds = Math.floor((now - past) / 1000);
      // คำนวณปี
      let interval = seconds / 31536000;
      if (interval >= 1) {
        return Math.floor(interval) + " ปีที่แล้ว";
      }
      // คำนวณเดือน
      interval = seconds / 2592000;
      if (interval >= 1) {
        return Math.floor(interval) + " เดือนที่แล้ว";
      }
      // คำนวณวัน
      interval = seconds / 86400;
      if (interval >= 1) {
        return Math.floor(interval) + " วันที่แล้ว";
      }
      // คำนวณชั่วโมง
      interval = seconds / 3600;
      if (interval >= 1) {
        return Math.floor(interval) + " ชั่วโมงที่แล้ว";
      }
      // คำนวณนาที
      interval = seconds / 60;
      if (interval >= 1) {
        return Math.floor(interval) + " นาทีที่แล้ว";
      }
      return "ไม่กี่วินาทีที่แล้ว";
    }

  }
};
</script>

<style lang="scss" scoped>
.Announcement-Top-News {
  background-color: #ffffff;
  width: 100%;
  margin: auto;
  padding: 15px;
  font-family: 'Arial', 'Sarabun' !important;
  border: 2px solid #eeeded;
  border-radius: 8px;

  .bookmarked i {
    color: #ff8800;
    /* สีทองสำหรับสถานะบันทึก */
  }

  .not-bookmarked i {
    color: #cccccc;
    /* สีเทาสำหรับสถานะไม่บันทึก */
  }

  .news-image-Prioritization {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }


  .top-stories-header {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-left: 15px;
  }

  .head {
    width: 100%;
    background-color: transparent;

    .news-image-container {
        position: relative;
        width: 100%;
        height: 220px; /* ความสูงคงที่ */
        display: flex;
        justify-content: center; /* จัดภาพให้อยู่กลางแนวนอน */
        align-items: center;     /* จัดภาพให้อยู่กลางแนวตั้ง */
        overflow: hidden;
        border-radius: 10px;
        background-color: #f2f2f2; /* กรณีรูปไม่มี */
      }

      .news-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; /* ให้รูปอยู่ในกรอบโดยไม่บิดเบี้ยว */
        border-radius: 10px;
      }

    .Title-Image {
      display: flex;
      /* จัดเรียงในแถว */
      align-items: center;
      /* จัดตำแหน่งให้ตรงกลางแนวนอน */
      margin-bottom: 2px;
    }

    .Topic {
      font-size: 26px !important;
      /* ขนาดตัวอักษร */
      font-weight: bold;
      color: #004080;
      padding-right: 10px;
      /* เว้นระยะห่างขวาเพื่อไม่ให้ติดปุ่ม */
    }

    .Prioritization {
      padding: 1px;
    }

    .Department {
      margin-top: 10px !important;
    }

    .button-tooltip {
      font-size: 10px;
      font-weight: bold;
      border-radius: 5px;
      margin: 5px 5px;
      padding: 5px 5px;
      background-color: transparent !important;
      color: rgb(0, 0, 0);
      border: none;
    }

    .tooltip-department {
      background-color: #A0A0A0 !important;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      padding: 5px;
    }

    .avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }

    .avatar-placeholder {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8px !important;
      font-weight: bold;
      color: #fff;
      margin-bottom: -18px;
      margin-right: 5px;
    }

    .author-name {
      margin-bottom: -18px;
      font-size: 8px !important;
      font-weight: bold;
      color: #333;
    }

    .Title {
      font-size: 18px;
      /* ขนาดใหญ่สุด */
      font-weight: bold;
      color: #333;
      margin-bottom: 2px;

    }

    .content {
      font-size: 14px;
      color: #555;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #888;
      margin-top: 10px;
    }

    .Footer-From {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .footer-avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px !important;
    }

    .footer-avatar-placeholder {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8px !important;
      font-weight: bold;
      color: #fff;
      margin-right: 5px;
    }
    .footer-author-name {
      font-size: 12px !important;
      font-weight: bold;
      color: #333;
    }

    .footer-btn i {
      color: #007bff;
      /* สีฟ้าหรือสีที่คุณต้องการ */
      font-size: 16px;
      /* ขนาดของไอคอน */
    }

    .footer-btn {
      background: none;
      /* ไม่มีพื้นหลัง */
      border: none;
      /* ไม่มีกรอบ */
      cursor: pointer;
      /* เปลี่ยนเป็น cursor ปกติเมื่อ hover */
    }

    .footer-btn:hover i {
      color: #0056b3;
      /* เปลี่ยนสีเมื่อ hover */
    }

    .comment-section {
      padding: 2px 0; // ลดจาก 5px
    }

    .comment-header {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .comment-avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    .user-name {
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
    }

    .comment-time {
      font-size: 10px;
      color: gray;
      line-height: 1;
    }

    .comment-text {
      margin-top: 3px; // ลดจาก 5px
      font-size: 12px; // ลดขนาดตัวอักษรนิดหน่อย
      line-height: 1.4; // ควบคุมความสูง
      margin-bottom: 2px; // เพิ่มความกระชับ
    }

  }

  .Another {
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px
  }

  .Another .news-image-Prioritization {
    width: 40px;
    height: 40px;
    object-fit: cover
  }

  .Another .button-tooltip {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #A0A0A0;
    color: white;
    border: none;
    font-size: 7px;
    font-weight: bold;
    padding: 0;
    align-self: center
  }

  .Another .Prioritization {
    padding: 1px
  }

  .Another .Topic {
    font-size: 20px !important;
    font-weight: bold;
    color: #004080
  }

  .Another .Department {
    width: auto !important;
    overflow-x: auto !important;
    white-space: nowrap !important
  }

  .Another .news-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%
  }

  .Another .news-item {
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px
  }

  .Another .card-news {
    width: 100%;
    background: #fff;
    border: none;
    text-align: left;
    padding: 10px;
    overflow: hidden;
    word-wrap: break-word
  }

  .Another .Title-Image {
    display: flex;
    align-items: center
  }

  .Another .Title {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap
  }

  .Another .content {
    font-size: 12px;
    font-family: 'Arial';
    color: #555
  }

.Another .news-image-container{
  position: relative;
        width: 100%;
        height: 120px; /* ความสูงคงที่ */
        display: flex;
        justify-content: center; /* จัดภาพให้อยู่กลางแนวนอน */
        align-items: center;     /* จัดภาพให้อยู่กลางแนวตั้ง */
        overflow: hidden;
        border-radius: 10px;
        background-color: #f2f2f2; /* กรณีรูปไม่มี */
}
.Another .news-image {
  max-width: 100%;
        max-height: 100%;
        object-fit: contain; /* ให้รูปอยู่ในกรอบโดยไม่บิดเบี้ยว */
        border-radius: 10px;
  }

  .Another .avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px
  }

  .Another .avatar-placeholder {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin-right: 10px
  }

  .Another .author-name {
    font-size: 1rem;
    font-weight: bold;
    color: #333
  }

  .Another .footer {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #888;
    margin-top: 5px
  }

  .Another .Footer-From {
    display: flex;
    align-items: center;
    margin-bottom: 10px
  }

  .Another .footer-avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px !important
  }

  .Another .footer-avatar-placeholder {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px !important;
    font-weight: bold;
    color: #fff;
    margin-right: 5px
  }

  .Another .footer-author-name {
    font-size: 12px !important;
    font-weight: bold;
    color: #333
  }

  .Another .footer-btn i {
    color: #007bff;
    font-size: 16px
  }

  .Another .footer-btn {
    background: none;
    border: none;
    cursor: pointer
  }

  .Another .footer-btn:hover i {
    color: #0056b3
  }

  .Another .comment-section {
    padding: 2px 0
  }

  .Another .comment-header {
    font-weight: bold;
    margin-bottom: 5px
  }

  .Another .comment-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%
  }

  .Another .user-name {
    font-size: 12px;
    font-weight: bold;
    line-height: 1
  }

  .Another .comment-time {
    font-size: 10px;
    color: gray;
    line-height: 1
  }

  .Another .comment-text {
    margin-top: 3px;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 2px
  }

  .Another .news-divider {
    border: 0;
    height: 1px;
    background: brown;
    margin: 10px 0
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(214, 214, 214, 0.829);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: hidden;
  }
 
}
</style>
