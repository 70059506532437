<template>
  <div class="modal-News">
    <button class="btn-icon" @click="closeModal">
      <i class="fa fa-times-circle"></i>
    </button>
    <div class="modal-wrapper">
      <img :src="modalContent.Images ? modalContent.Images : require('./images/NoImg.svg')" alt="Image"
        class="images-model" :class="{ fullscreen: isFullScreen }" @click="toggleFullScreen" />
    </div>
    <div class="modal-News-body" v-if="!isFullScreen">
      <div class="modal-News-topic">
        <div v-if="modalContent.Prioritization == 'IMPORTANT'">
          <i class="fa fa-star" style="color: #ffa500ff; margin-right: 5px;  font-size: 25px;" aria-hidden="true"></i>
        </div>
        <div v-if="modalContent.Prioritization == 'GENERAL'">
          <i class="fa fa-graduation-cap" style="color: #3f51b5; margin-right: 5px; font-size: 25px;"
            aria-hidden="true"></i>
        </div>
        <div v-if="modalContent.Prioritization == 'CRITICAL'">
          <i class="fa fa-exclamation-triangle" style="color: #dc3545ff; margin-right: 5px; font-size: 25px;"
            aria-hidden="true"></i>
        </div>
        <span class="topic-text" :style="{
          color: getColor(modalContent.Prioritization)
        }">
          {{ modalContent.Topic }}</span>
      </div>
      <div class="Title" v-html="addIndentTitle(modalContent.Title)"></div>
      <div class="Content" v-html="addIndent(modalContent.Content)"></div>
      <div class="Detail" v-html="modalContent.Detail"></div>
      <div class="Department">
        <button v-for="(dept, index) in modalContent.Department" :key="index" class="btn btn-sm mx-1" :style="{
          backgroundColor: getColorDepartment(dept.value),
          color: dept.value === 'WA' || dept.value === 'MT' ? '#000000' : '#fff' + ' !important',
          fontSize: '8px',
          fontWeight: 'bold',
          padding: '5px 5px',
        }">
          {{ dept.label }}
        </button>
      </div>
      <div class="FileandLink">
        <div class="col">
          <label style="font-size: 24px;" v-if="modalContent.File">File:</label>
          <a style="font-size: 20px; font-weight: bold;" v-if="modalContent.File" :href="modalContent.File"
            target="_blank">Download</a>
        </div>
        <div class="col">
          <label style="font-size: 24px;" v-if="modalContent.Link">Link:</label>
          <a style="font-size: 20px; font-weight: bold;" v-if="modalContent.Link" :href="modalContent.Link"
            target="_blank" rel="noopener noreferrer">
            {{ modalContent.Link }}
          </a>
        </div>
      </div>
      <hr style="margin: 10px 0;" />
      <div class="modal-News-footer">
        <div class="footer-left">
          <small class="time-footer">
            <small>{{ timeSince(modalContent.updatedAt) }}</small>
          </small>
          <template v-if="modalContent?.Avatar" style="margin-left:10px">
            <img :src="modalContent.Avatar" alt="Author Avatar" class="footer-avatar" />
          </template>
          <template v-else>
            <div class="footer-avatar-placeholder" style="margin-left:10px">
              {{ modalContent?.Author?.charAt(0).toUpperCase() || "?" }}
            </div>
          </template>
          <!-- <span class="footer-author-name">{{ item?.Author || "Unknown" }}</span> -->
          <div class="footer-author-name">{{ modalContent?.Author || "Unknown" }}</div>
        </div>
        <div class="footer-right">
          <div class="footer-likes">
            <i class="likes-count fa fa-user" aria-hidden="true"></i>
            <p>{{ modalContent?.views || 0 }}</p>
          </div>
          <b-button v-b-toggle="`collapse-${modalContent.id}`" variant="primary" class="mx-1 footer-btn">
            <i class="fa fa-comments" aria-hidden="true"></i>
          </b-button>
        </div>
      </div>
      <!-- Modal (Collapse for Comments) for Each Card -->
      <b-collapse :id="`collapse-${modalContent.id}`" class="mt-3 collapse-comment">
        <hr style="margin-top: -10px;" />
        <b-card style="height: auto; width: 100%; border: none; margin-top: -15px;">
          <div v-for="(comment, index) in modalContent.Comments" :key="index" class="comment-section">
            <div class="row">
              <div class="col-1">
                <template v-if="comment?.userAvatar">
                  <img :src="comment.userAvatar" alt="User Avatar" class="comment-avatar" />
                </template>
                <template v-else>
                  <div class="comment-avatar-placeholder">
                    {{ comment?.userAvatar?.charAt(0).toUpperCase() || "?" }}
                  </div>
                </template>
              </div>
              <div class="col-10">
                <div class="comment-header d-flex align-items-center">
                  <div class="ml-1">
                    <span class="user-name">{{ comment.userName }}</span>
                    <span class="comment-time ml-3">{{ comment.timeAgo }}</span>
                  </div>
                </div>
                <p class="comment-text">{{ comment.text }}</p>
              </div>
              <div class="col-1">
                <i class="fa fa-trash" aria-hidden="true" @click="deleteComment(comment, index)"></i>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-3">
            <MazInput v-model="newCommentText" placeholder="Comment" class="mb-0 flex-grow-1" />
            <b-button type="submit" variant="primary" class="ml-3" @click="submitComment()">Comment</b-button>
          </div>
        </b-card>
      </b-collapse>
    </div>
  </div>

</template>
<script>
import moment from 'moment'
import firebase from 'firebase/app';
import { db, storage } from '../../../db'
export default {
  props: {
    modalContent: Object,  // เพิ่ม props นี้เพื่อรับข้อมูลจาก parent
  },
  data() {
    return {
      isFullScreen: false,
      newCommentText: "",
    };
  },
  methods: {
    submitComment() {
      if (this.newCommentText.trim() !== "") {
        const createdAtMoment = moment(new Date()).format("YYYYMMDDTHHmm");
        const newComment = {
          userAvatar: "https://www.w3schools.com/html/pic_trulli.jpg", // ตัวอย่าง avatar
          userName: this.$store.getters.user.displayName,
          text: this.newCommentText,
          createdAt: createdAtMoment, // เพิ่ม timestamp เมื่อคอมเมนต์ถูกส่ง
        };
        const announcementId = this.modalContent.id;  // ใส่ ID ของเอกสารที่คุณต้องการเพิ่มคอมเมนต์
        // เพิ่มคอมเมนต์ใน array 'comments' ของเอกสารใน Ft_announcement
        db.collection("Ft_announcement")
          .doc(announcementId)
          .update({
            Comments: firebase.firestore.FieldValue.arrayUnion(newComment)
          })
          .then(() => {
            // เช็กก่อนว่า Comments มีอยู่มั้ย
            if (!this.modalContent.Comments) {
              this.modalContent.Comments = [];
            }

            this.modalContent.Comments.push(newComment);
            this.newCommentText = "";
            //   console.log("Comment added!");
          })
          .catch(error => {
            console.error('Error adding comment: ', error);
          });
      }
    }
    ,
    deleteComment(commentToDelete, index) {
      const announcementId = this.modalContent.id;
      db.collection("Ft_announcement")
        .doc(announcementId)
        .update({
          Comments: firebase.firestore.FieldValue.arrayRemove(commentToDelete)
        })
        .then(() => {
          // ลบจาก array ใน frontend
          this.modalContent.Comments.splice(index, 1);
          //  console.log("Comment deleted!");
        })
        .catch((error) => {
          console.error("Error removing comment: ", error);
        });
    },
    addIndentTitle(content) {
      const maxLength = 100; // กำหนดความยาวสูงสุด (สามารถปรับได้)
      const indent = '&nbsp;'.repeat(5); // ย่อหน้า 5 ช่อง

      if (content.length > maxLength) {
        return content.split('\n').map(line => indent + line).join('<br/>');
      }

      return content; // ถ้าข้อความไม่เกินความยาว ก็ไม่ทำอะไร
    },
    addIndent(content) {
      const indent = '&nbsp;'.repeat(5); // ย่อหน้า 5 ช่อง
      // แทนที่บรรทัดใหม่ (\n) ให้เพิ่มการย่อหน้า
      return content.split('\n').map(line => indent + line).join('<br/>');
    },
    truncateHtml(content, maxLength) {
      let div = document.createElement("div");
      div.innerHTML = content;
      let text = div.innerText || div.textContent;
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    timeSince(dateStr) {
      // แปลงวันที่ให้เป็นรูปแบบที่ Date() อ่านได้
      const past = new Date(dateStr.replace(/-/g, '/'));
      const now = new Date();
      const seconds = Math.floor((now - past) / 1000);

      // คำนวณปี
      let interval = seconds / 31536000;
      if (interval >= 1) {
        return Math.floor(interval) + " ปีที่แล้ว";
      }

      // คำนวณเดือน
      interval = seconds / 2592000;
      if (interval >= 1) {
        return Math.floor(interval) + " เดือนที่แล้ว";
      }

      // คำนวณวัน
      interval = seconds / 86400;
      if (interval >= 1) {
        return Math.floor(interval) + " วันที่แล้ว";
      }

      // คำนวณชั่วโมง
      interval = seconds / 3600;
      if (interval >= 1) {
        return Math.floor(interval) + " ชั่วโมงที่แล้ว";
      }

      // คำนวณนาที
      interval = seconds / 60;
      if (interval >= 1) {
        return Math.floor(interval) + " นาทีที่แล้ว";
      }

      return "ไม่กี่วินาทีที่แล้ว";
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
    },
    closeModal() {
      this.$emit('closeModal');  // Emit event กลับไปยัง parent เพื่อปิด modal
    },
    getColor(value) {
      const importanceLevels = {
        'CRITICAL': '#dc3545',
        'IMPORTANT': '#FFA500',
        'GENERAL': '#A0A0A0', //
        'REFERENCE': '#none',
      };
      return importanceLevels[value] || '#cccccc'; // ถ้าไม่มีให้ใช้สีเทาเป็นค่า default
    },
    getColorDepartment(value) {
      const colors = {
        'DIR': '#2F6EBA', //*
        'MNG': '#4D96FF',//*
        'DR': '#274E13',//
        'ADMIN': '#4D96FF',//*
        'ASST': '#4D96FF',//*
        'FIN': '#FFB74D',//
        'DEV': '#FFA500',//*
        'PUR': '#FFA500',//*
        'AR': '#E64A19',//
        'IB': '#4FC3F7',//
        'RN': '#388E3C',//
        'PMD': '#388E3C',//
        "PNS": "#4FC3F7",// Partnership
        'PH': '#8BC34A',//
        'MT': '#C5E1A5',//* Colors.lightBlue.shade200 
        'WA': '#FFECB3',//
        'DV': '#B71C1C',///
        'HK': '#F9A825',///
        'KT': '#795548',//* Colors.brown
        "BF": "#FFCA28",//
        "HOP": "#01579B",//
        'All': '#F44336',///
      };
      return colors[value] || '#cccccc'; // Default Grey
    },

  },
};
</script>
<style lang="scss" scoped>
.modal-News {
  background-color: #ffffff;
  width: 100%;
  max-width: 900px;
  margin: auto;
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  font-family: 'Arial', 'Sarabun' !important;
  color: #333;
  display: flex;
  flex-direction: column;
  gap: 2px;

  max-height: 100vh; // ✅ เพิ่มตรงนี้
  overflow-y: auto; // ✅ เพิ่มตรงนี้
  overscroll-behavior: contain; // กัน scroll ซ้อนในมือถือ

  .images-model.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: contain;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999;
    border-radius: 0;
    margin: 0;
    padding: 0;
    cursor: zoom-out;
  }

  .collapse-comment {
    height: auto;
  }

  .comment-section {
    padding: 5px 0; // ลดจาก 5px
  }

  .comment-header {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .comment-avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .user-name {
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
  }

  .comment-time {
    font-size: 12px;
    color: gray;
    line-height: 1;
  }

  .comment-text {
    margin-top: 3px; // ลดจาก 5px
    font-size: 14px; // ลดขนาดตัวอักษรนิดหน่อย
    line-height: 1.4; // ควบคุมความสูง
    margin-bottom: 6px; // เพิ่มความกระชับ
  }

  .modal-News-body {
    display: flex;
    flex-direction: column;
    gap: 10px;

  }

  .modal-wrapper {
    display: flex;
    justify-content: center;
    /* จัดแนวนอน */
    align-items: center;
    /* จัดแนวตั้ง */
    height: 100%;
    /* หรือกำหนดขนาด container ตามต้องการ */
  }

  .images-model {
    width: 340px;
    height: auto;
    border-radius: 15px;
    object-fit: cover;
    margin-top: -10px;
  }

  .modal-News-topic {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 22px;
    font-weight: 700;
    color: #294cff;
    margin-top: 10px;
    line-height: 1.4;

    .priority-btn {
      color: #fff;
      font-weight: bold;
      font-size: 15px;
      padding: 5px 5px;
      margin-bottom: 2px;
      border: none;
      border-radius: 4px;
      cursor: default;
    }

    .topic-text {
      display: inline;
    }
  }

  .Title {
    font-size: 20px;
    font-weight: bold;
  }

  .Title .Content,
  .Detail {
    text-indent: 5ch;
    max-height: 270px;
    /* ปรับความสูงที่ต้องการ */
    overflow-y: auto;
    padding-right: 5px;
    line-height: 1.6;
    font-size: 15px;
    color: #444;
    /* สไตล์ scrollbar (optional) */
    scrollbar-width: thin;
    scrollbar-color: #999 #f1f1f1;
  }

  .Detail::-webkit-scrollbar {
    width: 5px;
  }

  .Detail::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 10px;
  }

  .Detail::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .FileandLink {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;

    .col {
      flex: 1; // ให้แต่ละคอลัมน์กว้างเท่ากัน
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    a {
      color: #1a73e8;
      text-decoration: none;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .modal-News-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    color: #888;
    margin-top: -12px;
    margin-bottom: -10px;
  }

  .footer-left {
    display: flex;
    align-items: center;
  }

  .footer-right {
    display: flex;
    align-items: center;
  }

  .footer-avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
  }

  .footer-avatar-placeholder {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    margin-right: 5px;
  }

  .footer-author-name {
    font-size: 12px;
    font-weight: bold;
    color: #333;
  }

  .footer-likes {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
  }

  .footer-btn i {
    color: #007bff;
    /* สีฟ้าหรือสีที่คุณต้องการ */
    font-size: 16px;
    /* ขนาดของไอคอน */
  }

  .footer-btn {
    background: none;
    border: none;
    cursor: pointer;
  }

  .footer-btn:hover i {
    color: #0056b3;
  }

  .btn-icon {
    display: flex;
    justify-content: flex-end;
    // position: absolute; 
    margin-inline-end: -18px;
    margin-top: -18px;
    right: 0;
    background: none;
    border: none;
    font-size: 10px;
    padding: 1px;
  }

  .btn-icon i {
    color: #cc1414;
    font-size: 25px;
  }
}
</style>
