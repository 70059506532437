<template>
  <div class="container-fluid">
    <div class="h5 text-secondary d-flex">
      <span class="mr-auto">
        Permission Management
      </span>
      <button class="btn btn-primary" @click="showAddRoleModal"><i class="fas fa-plus"></i> Add Role</button>
      <b-modal id="add-role-modal" hide-footer title="Add Role">
        <div class="container">
          <div class="row mt-2">
            <div class="col-12">
              <label>Role Name</label>
              <input type="text" class="form-control" v-model="role_name">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <button class="btn btn-primary btn-block" @click="addRole"><i class="fas fa-plus"></i> Add Role</button>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal id="edit-role-modal" hide-footer title="Edit Role">
        <div class="container">
          <div class="row mt-2">
            <div class="col-12">
              <label>Role Name</label>
              <input type="text" class="form-control" v-model="role_name">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 mt-2">
              <button class="btn btn-primary btn-block" @click="editRole"><i class="fas fa-save"></i> Save</button>
            </div>
            <div class="col-12 mt-2">
              <button class="btn btn-danger btn-block" @click="deleteRole"><i class="fas fa-trash"></i> Delete</button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <hr>

    <div class="table-responsive">
      <table class="table table-bordered">
        <thead class="thead-dark">
          <th class="sticky-header"></th>
          <th v-for="(role, index) in roles" :key="index" class="text-center">
            {{ role.name }}&nbsp;
            <i class="fas fa-edit" style="cursor: pointer;" @click="showEditRoleModal(role.id)"></i>
          </th>
        </thead>
        <tbody>
          <tr v-for="(page, xindex) in preparedPages" :key="xindex">
            <td class="sticky-column" :class="{'background-darkgrey': !page.parent }">
              <span v-if="!page.parent && !page.noChild" @click="toggleChild(page.name)" style="cursor: pointer;">
                <i class="fas fa-caret-down" v-if="page.showChild"></i>
                <i class="fas fa-caret-right" v-if="!page.showChild"></i>&nbsp;
                <b>{{ page.name }}</b>
              </span>
              <span v-else-if="page.noChild">
                <b>{{ page.name }}</b>
              </span>
              <span v-else>&emsp;{{ page.name }}</span>
            </td>
            <td v-for="(role, yindex) in roles" :key="yindex">
              <div 
                class="level mr-auto ml-auto" 
                @click="$bvModal.show(`perm-${xindex}-${yindex}`)"
                :class="getClass(roles[yindex][page.name])"
              />
              <b-modal :id="`perm-${xindex}-${yindex}`" hide-footer :title="`${page.name} [${role.name}] permission`">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex mt-2">
                        <div class="level one mr-2" @click="setPermission(role.id,page.name,1)">
                          <i class="fas fa-check text-white" v-if="!roles[yindex][page.name] || roles[yindex][page.name]===1"></i>
                        </div>
                        <span>None</span>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="level two mr-2" @click="setPermission(role.id,page.name,2)">
                          <i class="fas fa-check text-white" v-if="roles[yindex][page.name]===2"></i>
                        </div>
                        <span>View</span>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="level three mr-2" @click="setPermission(role.id,page.name,3)">
                          <i class="fas fa-check text-white" v-if="roles[yindex][page.name]===3"></i>
                        </div>
                        <span>View, Create</span>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="level four mr-2" @click="setPermission(role.id,page.name,4)">
                          <i class="fas fa-check text-white" v-if="roles[yindex][page.name]===4"></i>
                        </div>
                        <span>View, Create, Edit</span>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="level five mr-2" @click="setPermission(role.id,page.name,5)">
                          <i class="fas fa-check text-white" v-if="roles[yindex][page.name]===5"></i>
                        </div>
                        <span>View, Create, Edit, Delete</span>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </td>
          </tr> 
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  firestore() {
    return {
      roles: db.collection('Role').orderBy('created_at','asc')
    }
  },
  data() {
    return {
      role_name: null,
      role_id: null,
      roles: [],
      pages: [
        {
          name: 'Registration',
          //noChild: true,
          showChild: false,
        },
        {
          name: 'Visit Summary',
          showChild: false,
        },
        {
          name: 'Patient Summary',
          showChild: false,
        },       
        {
          name: 'Billing',
          showChild: false,
        },
        {
          name: 'Billing Dashboard',
          parent: 'Billing'
        },
        {
          name: 'Billing Summary',
          parent: 'Billing'
        },
        {
          name: 'Insurance',
          showChild: false,
        },
        {
          name: 'Invoice',
          showChild: false,  
        },
        // {
        //   name: 'Expense',
        //   showChild: false,
        // },
        // {
        //   name: 'Expense Dashboard',
        //   parent: 'Expense'
        // },
        // {
        //   name: 'Expense Summary',
        //   parent: 'Expense'
        // },     
        
        {
          name: 'Product',
          showChild: false,
        },
        {
          name: 'Product Summary',
          parent: 'Product',
        },
        {
          name: 'Service',
          showChild: false,
        },
        {
          name: 'Package',
          showChild: false,
        },
        {
          name: 'Sale',
          showChild: false,
        },        
        {
          name: 'Statistic',
          showChild: false,
        },
        {
          name: 'Patient Statistic',
          parent: 'Statistic'
        },
        {
          name: 'Discovery Statistic',
          parent: 'Statistic'
        },
        {
          name: 'Hotel Statistic',
          parent: 'Statistic'
        },
        {
          name: 'Address Statistic',
          parent: 'Statistic'
        },
        {
          name: 'Demographic Statistic',
          parent: 'Statistic'
        },
        {
          name: 'Register Statistic',
          parent: 'Statistic'
        },
        {
          name: 'Time Statistic',
          parent: 'Statistic'
        },
        {
          name: 'Staff',
          showChild: false,
        },
        {
          name: 'Staff Management',
          parent: 'Staff'
        },
        {
          name: 'Role Management',
          parent: 'Staff'
        },
        {
          name: 'Setting',
          showChild: false,        

        },
        {
          name: 'Branch',
          parent: 'Setting',
        },
        {
          name: 'Zone',
          parent: 'Setting',
        },
        {
          name: 'Hotel',
          parent: 'Setting',
        }
      ]
    }
  },
  methods:{
    showAddRoleModal(){
      this.role_name=null
      this.$bvModal.show('add-role-modal')
    },
    async addRole(){
      if(!this.role_name) {
        this.$alert('Please enter role name', null, 'error');
        return
      }
      let role_name = this.role_name

      let loader = this.$loading.show({})

      await db.collection('Role').doc().set({
        name: role_name.trim(),
        created_at: new Date()
      })

      loader.hide()
      this.$bvModal.hide('add-role-modal')
    },
    async showEditRoleModal(role_id) {
      this.$bvModal.show('edit-role-modal')
      let loader = this.$loading.show({})
      let roleRef = await db.collection('Role').doc(role_id).get()

      loader.hide()

      this.role_name = roleRef.data().name
      this.role_id = role_id
    },
    async editRole() {
      if (!this.role_name) {
        this.$alert('Please enter role name', null, 'error');
        return
      }
      let loader = this.$loading.show({})
      
      await db.collection('Role').doc(this.role_id).set({
        name: this.role_name
      }, {merge: true})

      loader.hide()
      this.$bvModal.hide('edit-role-modal')
    },
    async deleteRole() {
      this.$confirm(`Do you want to delete ${this.role_name} role?`).then(async () => {
        let loader = this.$loading.show({})
    
        await db.collection('Role').doc(this.role_id).delete()
    
        loader.hide()
        this.$bvModal.hide('edit-role-modal')
      });
    },
    async setPermission(role_id, name, level) {

      let loader = this.$loading.show({})

      let data = {}
      data[name]=level

      await db.collection('Role').doc(role_id).set(data, {merge:true})

      loader.hide()
    },
    getClass(level) {
      switch (level) {
        case 1:
          return 'one'
        case 2:
          return 'two'
        case 3:
          return 'three'
        case 4:
          return 'four'
        case 5:
          return 'five'
        default:
          return 'one'
      }
    },
    toggleChild(name){
      let page = this._.find(this.pages, p => p.name === name)
      page.showChild = !page.showChild
    }
  },
  computed: {
    preparedPages() {
      let pages = []

      for(let page of this.pages) {
        if(page.parent) {
          let parent = this._.find( this.pages, p=> p.name === page.parent)
          if(parent.showChild) {
            pages.push(page)
          }
        } else {
          pages.push(page)
        }
      }

      return pages
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
th, td {
  padding: 10px;
  border: 1px solid #ddd;
  max-width: 200px;
  min-width: 150px;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 1;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: #f2f2f2;
  z-index: 1;
}
.level {
  padding: 7.5px;
  padding-left: 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: #999999;
  // margin-left: auto;
  // margin-right: auto;
  cursor: pointer;
}

.one {
  background-color: #999999 !important;
}
.two {
  background-color: #FF6B6B !important;
}
.three {
  background-color: #FFD93D !important;
}
.four {
  background-color: #7ee179 !important;
}
.five {
  background-color: #4D96FF !important;
}

.background-darkgrey {
  background-color: #999999;
}
</style>