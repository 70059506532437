<template>
  <div class="container-fluid">
    <div class="row mt-2">
      <div class="col-3 text-right">
        <label>First name</label>
      </div>
      <div class="col-9">
        <input type="text" class="form-control" v-model="user.firstName" placeholder="First name">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 text-right">
        <label>Last name</label>
      </div>
      <div class="col-9">
        <input type="text" class="form-control" v-model="user.lastName" placeholder="Last name">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 text-right">
        <label>Email</label>
      </div>
      <div class="col-9">
        <input type="text" class="form-control" v-model="user.email" placeholder="example@worldmedcemter.com">
      </div>
    </div>
    <div class="row mt-2" v-if="!staffId">
      <div class="col-3 text-right">
        <label>Password</label>
      </div>
      <div class="col-9">
        <input type="password" class="form-control" v-model="user.password" placeholder="enter password">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 text-right">
        <label>Phone number</label>
      </div>
      <div class="col-9">
        <MazPhoneNumberInput
          no-example
          default-country-code="TH"
          :only-countries="['TH']"
          :placeholder="$t('phone_th')"
          v-model="user.phoneNumber"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 text-right">
        <label>Roles</label>
      </div>
      <div class="col-9">
        <object-select
          v-model="user.assigned_roles"
          :multiple="true"
          :options="roles.map( r => {
            return {
              id: r.id,
              name: r.name
            }
          })"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 text-right">
        <label>Department</label>
      </div>
      <div class="col-9">
        <select class="form-control" v-model="user.department">
          <option v-for="(department, index) of departments" :key="index" :value="department">{{ department }}</option>
        </select>
        <select class="form-control mt-2" v-model="user.unit">
          <option v-for="(unit, index) of units[user.department]" :key="index" :value="unit">{{ unit }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-2">
        <div class="col-3">
          
        </div>
        <div class="col-9">
          <b-form-checkbox
            v-model="user.isApproved"
            name="approve"
            :value="true"
            :unchecked-value="false"
          >
            Approve user
          </b-form-checkbox>
        </div>
      </div>
    <div class="row mt-2">
      <div class="col-12">
        <button v-if="staffId" @click="saveUser" class="btn btn-primary btn-block"><i class="fas fa-edit"></i> Edit Staff</button>
        <button v-else @click="saveUser" class="btn btn-primary btn-block"><i class="fas fa-plus"></i> Add Staff</button>
        <button v-if="staffId" @click="$bvModal.show('change-password')" class="btn btn-primary btn-block"><i class="fas fa-key"></i> Change Password</button>

        <b-modal id="change-password" title="change password" hide-footer>
          <input type="password" class="form-control" v-model="password">
          <button class="btn btn-primary btn-block" @click="changePassword(staffId, password)"><i class="fas fa-key"></i> Change Password</button>
        </b-modal>

        <button v-if="staffId && $store.getters.permission['Staff Management'] === 5" class="btn btn-danger btn-block" @click="deleteUser(staffId)"><i class="fa fas-delete"></i> Delete user</button>
      </div>
    </div>
  </div>
</template>

<script>
import {db,fb_function} from '../../db'
import permission from '../../mixins/permission'
const departments = [
  'Medical',
  'Operation',
  'Admin',
  'Finance',
  'Support'
]
const units = {
  'Medical': [
    'Doctor',
    'Quality Management',
    'Med Staff Org'
  ],
  'Operation': [
    'Business Operation',
    'Medical Operation'
  ],
  'Admin': [
    'Marketing',
    'Human Resources',
    'Expansion & Dev'
  ],
  'Finance': [
    'Accounting',
    'Finance'
  ],
  'Support': [
    'Purchasing & Inv',
    'Facility',
    'Design',
    'F&B',
    'Information Tech',
    'Legal'
  ]
}
export default {
  mixins: [permission],
  props: {
    staffId: {
      type: String
    }
  },
  async mounted() {
    let loader = this.$loading.show({})
    if(this.staffId) {
      let staffId = this.staffId
      let staffRef = await db.collection('User').doc(staffId).get()
      this.user = {
        firstName: staffRef.data().firstName || null,
        lastName: staffRef.data().lastName || null,
        email: staffRef.data().email || null,
        isApproved: staffRef.data().isApproved || null,
        phoneNumber: staffRef.data().phoneNumber || null,
        assigned_roles: staffRef.data().assigned_roles || [],
        department: staffRef.data().department || null,
        unit: staffRef.data().unit || null
      }
      this.password = null
    }
    loader.hide()
  },
  data() {
    return {
      user: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        isApproved: false,
        assigned_roles: [],
        password: null
      },
      roles: [],
      departments,
      units,
      password: null
    }
  },
  firestore() {
    return {
      roles: db.collection('Role')
    }
  },
  methods: {
    async saveUser() {
      if(!this.user.firstName && !this.user.lastName) {
        this.$alert('Please enter firstname and lastname.')
        return
      }
      if(!this.user.email) {
        this.$alert('Please enter email')
        return
      }
      if(!this.staffId && !this.user.password) {
        this.$alert('Please enter password')
        return
      }
      if(!this.user.phoneNumber) {
        this.$alert('Please enter phonenumber')
        return
      }


      let loader = this.$loading.show({})


      try {
        if(!this.staffId) {
          let user = {
            displayName: `${this.user.firstName ? this.user.firstName: ''} ${this.user.lastName ? this.user.lastName : ''}`,
            email: this.user.email,
            password: this.user.password,
            phoneNumber: this.user.phoneNumber,
            disabled: !this.user.isApproved
          }
          const createUser = fb_function.httpsCallable('createUser')
          let result = await createUser(user)

          let uid = result.data.uid

          //new staff
          await db.collection('User').doc(uid).set({
            ...this.user,
            displayName: `${this.user?.firstName} ${this.user?.lastName}`
          }, { merge: true})
          this.$alert('Adding new staff success!', null, 'success')
          this.$router.back()
          
        }else{

          delete this.user.password
          let user = {
            uid: this.staffId,
            displayName: `${this.user.firstName ? this.user.firstName: ''} ${this.user.lastName ? this.user.lastName : ''}`,
            email: this.user.email,
            phoneNumber: this.user.phoneNumber,
            disabled: !this.user.isApproved
          }

          const updateUser = fb_function.httpsCallable('updateUser')
          await updateUser(user)
          //edit staff
          await db.collection('User').doc(this.staffId).set({
            ...user,
            displayName: `${this.user?.firstName} ${this.user?.lastName}`
          }, { merge: true})
          this.$alert('edit staff info success!', null, 'success')
        }
        loader.hide()
      } catch (error) {
        loader.hide()
        this.$alert(error,null, 'error')
      }
    },
    deleteUser(staffId) {
      this.$confirm('Do you want to delete this user?').then(async () => {

        try {
          const deleteUser = fb_function.httpsCallable('deleteUser')
          await deleteUser({uid: staffId})
        } catch (error) {
          this.$alert(`${error}`, null, 'error')
          return
        }

        try {
          await db.collection('User').doc(staffId).delete()
        } catch (error) {
          this.$alert(`${error}`, null, 'error')
          return
        }
        
        this.$alert(`user: ${staffId} was deleted`, null, 'success')
        this.$router.push('/staffing')
      })

    },
    async changePassword(staffId, password) {

      let loader = this.$loading.show({})
      try {
        const changePassword = fb_function.httpsCallable('changePassword')
        console.log({
          uid: staffId,
          password: password
        })
        await changePassword({
          uid: staffId,
          password: password
        })
      } catch (error) {
        loader.hide()
        this.$alert(error, null, 'error')
        return
      }

      loader.hide()
      this.$alert(`password changed`, null, 'success')
      this.$bvModal.hide('change-password')
    }
  }
}
</script>
