<template>
  <div>
    <div class="row p-2">

      <div class="col-12">

        <div class="d-flex">
          <date-filter v-model="filter" @input="triggerInitData" :search-date-type.sync="filter.search_date_type" />
          <div v-if="currentMenu === 'Compare'">
            <label class="my-auto mx-1">How did you find us</label>
            <MazSelect v-model="filterCompare" search :options="HowDidYouFindUs.map(item => {
              return {
                label: item,
                value: item
              };
            })
              " />
          </div>

        </div>

      </div>
      <b-nav tabs justified class="mt-2">
        <b-nav-item @click="(currentMenu = menu.name)" v-for="(menu, index) in menus" :key="index"
          :active="menu.name === currentMenu">{{ menu.name }}</b-nav-item>
      </b-nav>
    </div>

    <div class="row p-2" v-if="currentMenu === 'Summary'">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th># of Patients</th>
          </thead>
          <tr v-for="(label, index) in chartData.labels" :key="label">
            <td>{{ label }}</td>
            <td>{{ chartData.datasets[0].data[index] }}</td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Pie :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" />
        <!-- <Bar :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" /> -->
      </div>
    </div>

    <div class="row p-2" v-if="currentMenu === 'Growth'">
      <div class="col-3">
        <table class="table table-bordered">
          <thead>
            <th>name</th>
            <th>amount</th>
          </thead>
          <tbody>
            <tr v-for="dataset in growthChartData.datasets" :key="dataset.label">
              <td>
                <b-form-checkbox v-model="selectedDiscovery" :value="dataset.label" > {{ dataset.label
                }}</b-form-checkbox>
              </td>
              <td>{{ _.sum(dataset.data) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-9">
        <LineChart :chart-options="chartGrowthOptions" :chart-data="growthChartData" :dataset-id-key="'label'"
          :chart-id="'discovery_growth_chart'" :width="400" :height="200" />
      </div>
    </div>

    <div class="row p-2" v-if="currentMenu === 'Compare'">
      <div class="col-12 mt-2">
        <LineChart :chart-options="chartGrowthOptions" :chart-data="compareChartData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chartx'" :width="400" :height="200" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Pie,
  Line as LineChart,
} from 'vue-chartjs/legacy'
import HowDidYouFindUs from '../../assets/how_did_you_find_us.json'
import color from '../../assets/color.json'
import Chart from 'chart.js/auto';
import { db } from '../../db'
import moment from "moment";
export default {
  // props: ['patients'],
  components: {
    Pie,
    LineChart,
  },

  data() {
    return {
      HowDidYouFindUs,
      // from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      // to: this.$moment().format('DD/MM/YYYY'),
      compare_first_m: this.$moment().add(-1, 'month').format('MM/YYYY'),
      compare_sec_m: this.$moment().format('MM/YYYY'),
      chartOptions: {
        responsive: true,
        plugins: {
          datalabels: {
            color: 'black',
            font: {
              weight: 'bold',
              size: 12,
            },
            formatter: (value, context) => {
              const dataset = context.chart.data.datasets[0]
              const total = dataset.data.reduce((sum, val) => sum + val, 0)

              if (total === 0) return '0%'

              const percent = ((value / total) * 100).toFixed(1)
              return `${value} (${percent}%)`
            }
          }
        }
      },
      chartGrowthOptions: {
        responsive: true,
        plugins: {
          datalabels: {
            color: 'black',
            font: {
              weight: 'bold',
              size: 12,
            },
            anchor: 'end', // attaches label to the top of the point
            align: 'top',  // positions it above the point
            offset: 4      // optional: spacing from the point
          }
        },
      },
      chartCompareOptions: {
        responsive: true,
      },
      menus: [
        { name: 'Summary' },
        { name: 'Growth' },
        { name: 'Compare' }
      ],
      currentMenu: 'Summary',
      selectedDiscovery: [],
      filterCompare: null,
      filteredPatients: [],
      filteredPatientsInFirstMonth: [],
      filter: {
        from: this.$moment().startOf('month').format('DD/MM/YYYY'),
        to: this.$moment().format('DD/MM/YYYY'),
        search_date_type: 'by-range'
      },
    }
  },

  mounted() {
    this.loadData()
    this.filterCompare = 'Hotel'
    //this.selectedDiscovery = this.datasets.filter(d => d.label !== 'Total').map(d => d.label)

  },
  computed: {
    chartData() {

      let filteredPatients = this.filteredPatients

      //label
      let labels = this.HowDidYouFindUs
      let sortedData = []
      for (let l of labels) {
        let count = 0
        for (let p of filteredPatients) {
          if (p.how_did_you_find_us) {
            if (p.how_did_you_find_us.includes(l)) count++;
          }
        }
        if (count > 0){
          sortedData.push({
            label: l,
            value: count
          })
        }        
      }   
      const ordered = this._.orderBy(sortedData, 'value', 'desc')
      return {
        labels: ordered.map(obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: ordered.map(obj => obj.value)
          }
        ]
      }
    },
    growthChartData() {

      let filteredPatients = this.filteredPatients

      let fromMoment = this.$moment(this.filter.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.filter.to, 'DD/MM/YYYY')

      console.log('filteredPatients: G', filteredPatients);

      //datasets
      let labels = this.HowDidYouFindUs
      let datasets = []

      labels.forEach((l, index) => {
        datasets.push({
          label: l,
          data: [],
          backgroundColor: color[index % 6],
          borderColor: color[index % 6],
          cubicInterpolationMode: 'monotone',
          hidden: !this.selectedDiscovery.includes(l),
        })
      })
      console.log('datasets:1 G ', datasets);

      //dateLabel
      let dateLabels = []
      let startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')

      while (startLabelMoment.isSameOrBefore(toMoment)) {
        dateLabels.push(startLabelMoment.format('DD/MM/YYYY'))
        startLabelMoment.add(1, 'day')
        datasets.map(dataset => {
          dataset.data.push(0)
        })
      }


      //count data
      startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')
      let i = 0
      while (startLabelMoment.isSameOrBefore(toMoment)) {
        for (let p of filteredPatients) {
          if (p.created_at && typeof p.created_at !== 'string') {
            if (this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date')) {
              datasets.forEach((dataset, dIndex) => {
                if (p.how_did_you_find_us?.includes(dataset.label)) datasets[dIndex].data[i]++
              })
            }
          }
        }
        startLabelMoment.add(1, 'day')
        i++
      }

      let totalData = dateLabels.map((l, index) => {
        let count = 0
        datasets.forEach(d => {
          count += d.data[index]
        })
        return count
      })

      datasets.push({
        label: 'Total',
        data: totalData,
        backgroundColor: color[6],
        borderColor: color[6],
        cubicInterpolationMode: 'monotone',
        hidden: !this.selectedDiscovery.includes('Total'),
      })

      const sortedDataset = _.sortBy(
      datasets.filter(d => _.sum(d.data) > 0), 
      d => {
        return -_.sum(d.data)
      })
      console.log('datasets: G', datasets);
      return {
        labels: dateLabels,
        datasets: sortedDataset
      }

    },
    compareChartData() {
      let labels = []
      for (let date = 1; date <= 31; date++) {
        labels.push(date)
      }
      let firstMonthPatientData = Array(31).fill(0)
      let secMonthPatientData = Array(31).fill(0)
      let totalData = Array(31).fill(0)
      let filteredPatientsInFirstMonth = []
      let filteredPatientsInSecMonth = []
      let firstMonthMoment = this.$moment(`${this.compare_first_m}`, 'MM/YYYY')
      let secMonthMoment = this.$moment(`${this.compare_sec_m}`, 'MM/YYYY')
      let patients =  [...this.filteredPatientsInFirstMonth, ...this.filteredPatients]
      for (let p of patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (this.$moment(p.created_at.toDate()).isSame(firstMonthMoment, 'month')) filteredPatientsInFirstMonth.push(p)
          if (this.$moment(p.created_at.toDate()).isSame(secMonthMoment, 'month')) filteredPatientsInSecMonth.push(p)
        }
      }
      for (let p of filteredPatientsInFirstMonth) {
        if (p.created_at && typeof p.created_at !== 'string' && p.how_did_you_find_us?.includes(this.filterCompare)) {
          let dayValue = this.$moment(p.created_at.toDate()).date()
          console.log('dayValue: ', dayValue);
          let dayValueIndex = dayValue - 1
          firstMonthPatientData[dayValueIndex] += 1
          totalData[dayValueIndex] += 1
        }
      }
      for (let p of filteredPatientsInSecMonth) {
        if (p.created_at && typeof p.created_at !== 'string' && p.how_did_you_find_us?.includes(this.filterCompare)) {
          let dayValue = this.$moment(p.created_at.toDate()).date()
          let dayValueIndex = dayValue - 1
          secMonthPatientData[dayValueIndex] += 1
          totalData[dayValueIndex] += 1
        }
      }
      const displayLabelFirstMonth = this.$moment(this.compare_first_m, 'MM/YYYY').format('MMM YYYY')
      const displayLabelSecMonth = this.$moment(this.compare_sec_m, 'MM/YYYY').format('MMM YYYY')
      return {
        labels,
        datasets: [
          {
            label: displayLabelFirstMonth,
            data: firstMonthPatientData,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgb(54, 162, 235)',

            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: displayLabelSecMonth,
            data: secMonthPatientData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgb(255, 99, 132)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          // {
          //   label: 'Total',
          //   data: totalData,
          //   borderWidth: 1,
          //   cubicInterpolationMode: 'monotone'
          // },
        ]
      }
    }
  },
  methods: {
    async loadData() {
      const loader = this.$loading.show({})
      this.filteredPatients = []
      try {

        let start = moment(this.filter.from, "DD/MM/YYYY").startOf('day').toDate()
        let end = moment(this.filter.to, "DD/MM/YYYY").endOf('day').toDate()

        let patientRef = await db.collection('Patient')
          .where("created_at", ">=", start)
          .where("created_at", "<", end)
          .get()
        this.filteredPatients = patientRef.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          }
        })

        start = moment(this.filter.from, "DD/MM/YYYY").subtract(1, 'month').startOf('month').toDate()
        end = moment(this.filter.to, "DD/MM/YYYY").subtract(1, 'month').endOf('month').toDate()
        patientRef = await db.collection('Patient')
          .where("created_at", ">=", start)
          .where("created_at", "<", end)
          .get()
        this.filteredPatientsInFirstMonth = patientRef.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          }
        })

      }
      catch (error) {
        console.error("Error getting documents: ", error);
      }
      finally {
        loader.hide()
      }
    },

    triggerInitData() {

      this.loadData()

    },
  },
}
</script>