<template>
  <div class="row p-2">

    <div class="col-12">
      <!-- <div class="d-flex">
        <label>between: </label>
        <MazPicker v-model="from" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
        <label>and</label>
        <MazPicker v-model="to" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
      </div> -->
      <date-filter v-model="filter" @input="triggerInitData" :search-date-type.sync="filter.search_date_type" />
    </div>
    <div class="col-6 mt-2">
      <table class="table table-bordered">
        <thead>
          <th></th>
          <th># of Patients</th>
        </thead>
        <tr v-for="(label, index) in chartData.labels" :key="`${label}${index}`">
          <td>{{ label }}</td>
          <td>{{ chartData.datasets[0].data[index] }}</td>
        </tr>
        <tr>
          <td>SUM</td>
          <td>{{chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
        </tr>
      </table>
    </div>
    <div class="col-6 mt-2">
      <Pie :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
        :chart-id="'patients_number_chart'" :width="400" :height="400" />
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import color from '../../assets/color.json'
import Chart from 'chart.js/auto';
import { db } from '../../db'
import moment from "moment"
export default {
  //props: ['patients'],
  components: {
    Pie
  },
  data() {
    return {
      hotels: _.filter(this.$store.state.places, { type: 'hotel' }).map(c => {
        return c.name_en;
      }),
      // from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      // to: this.$moment().format('DD/MM/YYYY'),
      chartOptions: {
        responsive: true,
        plugins: {
          datalabels: {
            color: 'black',
            font: {
              weight: 'bold',
              size: 12,
            },
            formatter: (value, context) => {
              const dataset = context.chart.data.datasets[0]
              const total = dataset.data.reduce((sum, val) => sum + val, 0)

              if (total === 0) return '0%'

              const percent = ((value / total) * 100).toFixed(1)
              return `${value} (${percent}%)`
            }
          }
        }
      },
      filteredPatients: [],
      filter: {
        from: this.$moment().startOf('month').format('DD/MM/YYYY'),
        to: this.$moment().format('DD/MM/YYYY'),
        search_date_type: 'by-range'
      },
    }
  },
  computed: {
    chartData() {

      let filteredPatients = this.filteredPatients

      //label
      let labels = this.hotels
      let sortedData = []
      for (let l of labels) {
        let count = 0
        for (let p of filteredPatients) {
          if (p.tourist_hotel === l) count++
        }

        // ✅ Only include if count > 0
        if (count > 0) {
          sortedData.push({
            label: l,
            value: count
          })
        }
      }

      const ordered = this._.orderBy(sortedData, 'value', 'desc')

      return {
        labels: ordered.map(obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: ordered.map(obj => obj.value)
          }
        ]
      }
    }
  },
  methods: {
    async loadData() {
      const loader = this.$loading.show({})
      this.filteredPatients = []
      try {

        let start = moment(this.filter.from, "DD/MM/YYYY").startOf('day').toDate()
        let end = moment(this.filter.to, "DD/MM/YYYY").endOf('day').toDate()

        let patientRef = await db.collection('Patient')
          .where("created_at", ">=", start)
          .where("created_at", "<", end)
          .get()
        this.filteredPatients = patientRef.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          }
        })

      }
      catch (error) {
        console.error("Error getting documents: ", error);
      }
      finally {
        loader.hide()
      }
    },

    triggerInitData() {

      this.loadData()

    },
  }
}
</script>