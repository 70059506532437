<template>
  <div>
    <div class="row p-2">

      <div class="col-12">
        <date-filter v-model="filter" @input="triggerInitData" :search-date-type.sync="filter.search_date_type" />

      </div>

      <b-nav tabs justified class="mt-2">
        <b-nav-item @click="(currentMenu = menu.name)" v-for="(menu, index) in menus" :key="index"
          :active="menu.name === currentMenu">{{ menu.name }}</b-nav-item>
      </b-nav>

    </div>

    <div class="row p-2" v-if="currentMenu === 'Summary'">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th>HOSPITAL</th>
            <th>SLINKY REFERRAL</th>
            <th>AESTHETIC</th>
            <th>PIER REFERRAL</th>
            <th>CENTRAL REFERRAL</th>
            <th>TOTAL</th>
          </thead>
          <tr v-for="(label, index) in chartData.labels" :key="label">
            <td>{{ label }}</td>
            <td>{{ chartData.datasets[0].data[index] }}</td>
            <td>{{ chartData.datasets[1].data[index] }}</td>
            <td>{{ chartData.datasets[2].data[index] }}</td>
            <td>{{ chartData.datasets[3].data[index] }}</td>
            <td>{{ chartData.datasets[4].data[index] }}</td>
            <td>
              {{ chartData.datasets[0].data[index] + chartData.datasets[1].data[index] +
                chartData.datasets[2].data[index] + chartData.datasets[3].data[index] + chartData.datasets[4].data[index]
              }}
            </td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
            <td>{{chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
            <td>{{chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
            <td>{{chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
            <td>{{chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
            <td>{{chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) +
              chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) +
              chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0) +
              chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0) +
              chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0)}}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Bar :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" />
      </div>
    </div>

    <div class="row p-2" v-if="currentMenu === 'Growth'">
      <div class="col-12 mt-2">
        <LineChartGenerator :chart-options="chartOptions2" :chart-data="growthChartData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="200" />
      </div>
    </div>
    <div class="row p-2" v-if="currentMenu === 'Compare'">
      <div class="col-12 mt-2">
        <LineChartGenerator :chart-options="chartOptions2" :chart-data="compareChartData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="200" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Bar,
  Line as LineChartGenerator
} from 'vue-chartjs/legacy'
import Chart from 'chart.js/auto';
import { db } from '../../db'
import moment from "moment";
import { filter } from 'lodash';
export default {
  // props: ['patients'],
  components: {
    Bar,
    LineChartGenerator
  },
  data() {
    return {
      // from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      // to: this.$moment().format('DD/MM/YYYY'),
      compare_first_m: this.$moment().add(-1, 'month').format('MM/YYYY'),
      compare_sec_m: this.$moment().format('MM/YYYY'),

      chartOptions: {
        responsive: true,
        plugins: {

          legend: {
            labels: {
              color: 'black' // legend label font color
            }
          },
          datalabels: {
            color: 'white', // font color inside bars
            font: {
              weight: 'bold',
              size: 12
            },
            formatter: (value, context) => {
              const allData = context.chart.data.datasets;
              const index = context.dataIndex;

              // Calculate total for the same index across all bars
              let total = 0;
              for (let ds of allData) {
                // Avoid "Total" dataset when summing
                if (ds.label !== 'Total') {
                  total += ds.data[index] || 0;
                }
              }

              if (total === 0) return '0%'; // prevent division by zero

              const percent = ((value / total) * 100).toFixed(1);
              return `${value} (${percent}%)`; // or `${value} (${percent}%)`
            }
          },
          tooltip: {
            bodyColor: 'white',
            titleColor: 'white'
          }
        },
        scales: {
          x: {
            ticks: {
              color: 'black'
            }
          },
          y: {
            ticks: {
              color: 'black'
            }
          }
        }
      },
      chartOptions2: {
        responsive: true,
        plugins: {
          datalabels: {
            color: 'black',
            font: {
              weight: 'bold',
              size: 12,
            },
            anchor: 'end', // attaches label to the top of the point
            align: 'top',  // positions it above the point
            offset: 4      // optional: spacing from the point
          }
        },
      },
      menus: [
        { name: 'Summary' },
        { name: 'Growth' },
        { name: 'Compare' },
      ],
      currentMenu: 'Summary',
      filteredPatients: [],
      filteredPatientsInFirstMonth: [],
      filter: {
        from: this.$moment().startOf('month').format('DD/MM/YYYY'),
        to: this.$moment().format('DD/MM/YYYY'),
        search_date_type: 'by-range'
      },

    }
  },
  mounted() {
    this.loadData()
  },
  computed: {
    chartData() {

      let filteredPatients = this.filteredPatients

      let fromMoment = this.$moment(this.filter.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.filter.to, 'DD/MM/YYYY')

      // generate months labels
      //label
      let labels = []
      let startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')

      while (startLabelMoment.isSameOrBefore(toMoment)) {
        labels.push(startLabelMoment.format('MMM YYYY'))
        startLabelMoment.add(1, 'month')
      }

      //data
      startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')
      let hospitalData = []
      let slinkyData = []
      let aestheticData = []
      let pierData = []
      let centralData = []
      let totalData = []
      while (startLabelMoment.isSameOrBefore(toMoment)) {
        let hcount = 0
        let scount = 0
        let acount = 0
        let pcount = 0
        let ccount = 0
        for (let p of filteredPatients) {
          if (p.created_at && typeof p.created_at !== 'string') {
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch === 1
            ) hcount++
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch === 2
            ) scount++
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch === 3
            ) acount++
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch === 4
            ) pcount++
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch === 5
            ) ccount++
          }
        }
        hospitalData.push(hcount)
        slinkyData.push(scount)
        aestheticData.push(acount)
        pierData.push(pcount)
        centralData.push(ccount)
        totalData.push(hcount + scount + acount + pcount + ccount)
        startLabelMoment.add(1, 'month')
      }


      return {
        labels,
        datasets: [
          {
            label: 'HOSPITAL',
            data: hospitalData,
            backgroundColor: [
              'rgb(25, 118, 210)',
            ],
            borderColor: [
              'rgb(25, 118, 210)',

            ],
            borderWidth: 1
          },
          {
            label: 'SLINKY REFERRAL',
            data: slinkyData,
            backgroundColor: [
              'rgb(211, 47, 47)',
            ],
            borderColor: [
              'rgb(211, 47, 47)',
            ],
            borderWidth: 1
          },
          {
            label: 'AESTHETIC',
            data: aestheticData,
            backgroundColor: [
              'rgb(251, 192, 45)',
            ],
            borderColor: [
              'rgb(251, 192, 45)',
            ],
            borderWidth: 1
          },
          {
            label: 'PIER REFERRAL',
            data: pierData,
            backgroundColor: [
              'rgb(56, 142, 60)',
            ],
            borderColor: [
              'rgb(56, 142, 60)',
            ],
            borderWidth: 1
          },
          {
            label: 'CENTRAL REFERRAL',
            data: centralData,
            backgroundColor: [
              'rgb(123, 31, 162)',
            ],
            borderColor: [
              'rgb(123, 31, 162)',
            ],
            borderWidth: 1
          },
          // {
          //   label: 'Total',
          //   data: totalData,
          //   borderWidth: 1
          // },

        ]
      }
    },
    growthChartData() {
      let filteredPatients = this.filteredPatients

      let fromMoment = this.$moment(this.filter.from, 'DD/MM/YYYY')
      // console.log('fromMoment: ', fromMoment);
      let toMoment = this.$moment(this.filter.to, 'DD/MM/YYYY')
      // console.log('toMoment: ', toMoment);
      // generate months labels
      //label
      let labels = []
      let startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')

      while (startLabelMoment.isSameOrBefore(toMoment)) {
        labels.push(startLabelMoment.format('DD/MM/YYYY'))
        startLabelMoment.add(1, 'day')
      }

      //data
      startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')
      let hospitalData = []
      let slinkyData = []
      let aestheticData = []
      let pierData = []
      let centralData = []
      let totalData = []
      while (startLabelMoment.isSameOrBefore(toMoment)) {
        let hcount = 0
        let scount = 0
        let acount = 0
        let pcount = 0
        let ccount = 0
        for (let p of filteredPatients) {
          if (p.created_at && typeof p.created_at !== 'string') {
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              p.branch === 1
            ) hcount++
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              p.branch === 2
            ) scount++
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              p.branch === 3
            ) acount++
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              p.branch === 4
            ) pcount++
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              p.branch === 5
            ) ccount++
          }
        }
        hospitalData.push(hcount)
        slinkyData.push(scount)
        aestheticData.push(acount)
        pierData.push(pcount)
        centralData.push(ccount)
        totalData.push(hcount + scount + acount + pcount + ccount)
        startLabelMoment.add(1, 'day')
      }
      return {
        labels,
        datasets: [
          {
            label: 'HOSPITAL',
            data: hospitalData,
            backgroundColor: [
              'rgb(25, 118, 210)',
            ],
            borderColor: [
              'rgb(25, 118, 210)',

            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'SLINKY REFERRAL',
            data: slinkyData,
            backgroundColor: [
              'rgb(211, 47, 47)',
            ],
            borderColor: [
              'rgb(211, 47, 47)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'AESTHETIC',
            data: aestheticData,
            backgroundColor: [
              'rgb(251, 192, 45)',
            ],
            borderColor: [
              'rgb(251, 192, 45)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'PIER REFERRAL',
            data: pierData,
            backgroundColor: [
              'rgb(56, 142, 60)',
            ],
            borderColor: [
              'rgb(56, 142, 60)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'CENTRAL REFERRAL',
            data: centralData,
            backgroundColor: [
              'rgb(123, 31, 162)',
            ],
            borderColor: [
              'rgba(123, 31, 162)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          // {
          //   label: 'Total',
          //   data: totalData,
          //   borderWidth: 1,
          //   cubicInterpolationMode: 'monotone'
          // },
        ]
      }
    },
    compareChartData() {
      let labels = []
      // label data from 1 to 31
      for (let date = 1; date <= 31; date++) {
        labels.push(date)
      }
      let firstMonthPatientData = Array(31).fill(0)
      let secMonthPatientData = Array(31).fill(0)
      let totalData = Array(31).fill(0)
      let filteredPatientsInFirstMonth = []
      let filteredPatientsInSecMonth = []
      let firstMonthMoment = moment(this.filter.from, "DD/MM/YYYY").subtract(1, 'month')
      let secMonthMoment = this.$moment(this.filter.from, 'DD/MM/YYYY')
      let patients = [...this.filteredPatientsInFirstMonth, ...this.filteredPatients]
      for (let p of patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (this.$moment(p.created_at.toDate()).isSame(firstMonthMoment, 'month')) filteredPatientsInFirstMonth.push(p)
          if (this.$moment(p.created_at.toDate()).isSame(secMonthMoment, 'month')) filteredPatientsInSecMonth.push(p)
        }
      }
      for (let p of filteredPatientsInFirstMonth) {
        if (p.created_at && typeof p.created_at !== 'string') {
          let dayValue = this.$moment(p.created_at.toDate()).date()

          let dayValueIndex = dayValue - 1
          firstMonthPatientData[dayValueIndex] += 1
          totalData[dayValueIndex] += 1
        }
      }
      for (let p of filteredPatientsInSecMonth) {
        if (p.created_at && typeof p.created_at !== 'string') {
          let dayValue = this.$moment(p.created_at.toDate()).date()
          let dayValueIndex = dayValue - 1
          secMonthPatientData[dayValueIndex] += 1
          totalData[dayValueIndex] += 1
        }
      }
      const displayLabelFirstMonth = firstMonthMoment.format('MMM YYYY')

      const displayLabelSecMonth = secMonthMoment.format('MMM YYYY')
      return {
        labels,
        datasets: [
          {
            label: displayLabelFirstMonth,
            data: firstMonthPatientData,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgb(54, 162, 235)',

            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: displayLabelSecMonth,
            data: secMonthPatientData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgb(255, 99, 132)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          // {
          //   label: 'Total',
          //   data: totalData,
          //   borderWidth: 1,
          //   cubicInterpolationMode: 'monotone'
          // },
        ]
      }
    }
  },
  methods: {
    async loadData() {
      const loader = this.$loading.show({})
      this.filteredPatients = []
      try {

        let start = moment(this.filter.from, "DD/MM/YYYY").startOf('day').toDate()
        let end = moment(this.filter.to, "DD/MM/YYYY").endOf('day').toDate()

        let patientRef = await db.collection('Patient')
          .where("created_at", ">=", start)
          .where("created_at", "<", end)
          .get()
        this.filteredPatients = patientRef.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          }
        })

        start = moment(this.filter.from, "DD/MM/YYYY").subtract(1, 'month').startOf('month').toDate()
        end = moment(this.filter.to, "DD/MM/YYYY").subtract(1, 'month').endOf('month').toDate()
        patientRef = await db.collection('Patient')
          .where("created_at", ">=", start)
          .where("created_at", "<", end)
          .get()
        this.filteredPatientsInFirstMonth = patientRef.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          }
        })

      }
      catch (error) {
        console.error("Error getting documents: ", error);
      }
      finally {
        loader.hide()
      }
    },
    triggerInitData() {

      this.loadData()

    },
  },
  watch: {
    // filter: {
    //   deep: true,
    //   handler() {
    //     this.loadData()
    //   }
    // }
  }
}
</script>